import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { withRouter } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { faBars } from '@fortawesome/free-solid-svg-icons'
// Language translate import
import { withTranslation } from 'react-i18next';
// check client Authentication
import { checkClientAuthentication } from '../../utils/restriction';
const navitems = ["/CaseList", "/CaseDetails", "/DocumentDetails", "/ThreadDetails", "/Chat"];
class Sidebar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            clientAuth: true,
            show: false,
            sideBar: []
        }

    }
    componentWillMount() {
        const clientAuthentication = checkClientAuthentication();
        let SideBar;
        let sortedSideBar = [];
        if (clientAuthentication) {
            this.setState({
                clientAuth: clientAuthentication
            })
        } else {
            this.setState({
                clientAuth: clientAuthentication
            })
        }       
        let languageCode=localStorage.getItem("language");
        const sideBarEN = window.globalConfig.sideBarEN;
        const sideBarNO = window.globalConfig.sideBarNO;
        let sideMenu = languageCode==="en"? sideBarEN:sideBarNO;
        let sideBarMenu = [
            {
                key: 'Dashboard',
                value: <li key={"Dashboard"}><NavLink id ="Dashboard" activeClassName="active" to="/Dashboard" >{sideMenu.dashBoard}</NavLink></li>
            },
            {
                key: 'Booking',
                value: this.state.clientAuth ? <li key={"Booking"}><NavLink  id ="Booking"  activeClassName="active" to="/Booking">{sideMenu.booking}</NavLink></li> : null
            },
            {
                key: 'CaseList',
                value: this.state.clientAuth ? <li key={"CaseList"}><Link  id ="CaseList" className={navitems.includes(this.props.match.path) ? "active" : ""} to="/CaseList">{sideMenu.case}</Link></li> : null
            },
            {
                key: 'News',
                value: <li key={"News"}><NavLink  id ="News" activeClassName="active" to="/News">{sideMenu.news}</NavLink></li>
            },
            {
                key: 'Profile',
                value: this.state.clientAuth ? <li key={"Profile"}><NavLink  id ="Profile" activeClassName="active" to="/Profile">{sideMenu.myProfile}</NavLink></li> : null
            },
            {
                key: 'Inquiry',
                value: this.state.clientAuth ? <li key={"Inquiry"}><NavLink  id ="Inquiry" activeClassName="active" to="/Inquiry">{sideMenu.inquiry}</NavLink></li> : null
            },
        ]
        const data = localStorage.getItem("sideBar");
        if (data !== undefined && data !== "" && data !== null) {
            SideBar = JSON.parse(data);
            for (let i = 0; i < SideBar.length; i++) {
                sideBarMenu.filter(function (item) {
                  let  sideBarConfig = SideBar[i].split(":")[0];
                    if (item.key === sideBarConfig.toString()) {
                        sortedSideBar.push(item.value);
                    }
                });
            }
        }
        else {
            for (let i = 0; i < sideBarMenu.length; i++) {
                sortedSideBar.push(sideBarMenu[i].value);
            }
        }
        this.setState({
            sideBar: sortedSideBar
        });
    }


    handleToogle = () => {
        this.setState({
            show: !this.state.show
        })
    }

    render() {
        return (
            <div>
                <ul className="sidebarMobile">
                    <li><Button onClick={this.handleToogle}><FontAwesomeIcon icon={faBars} /></Button></li>
                </ul>
                <div className={this.state.show ? "sidebar-"+window.globalConfig.CustomerName+" sidebarOpen" : "sidebar-"+window.globalConfig.CustomerName}>
                    <ul className="sidebarMenu">
                        {this.state.sideBar.map((data) => (data))}
                    </ul>
                </div>
            </div>
        )
    }
}

export default withRouter(withTranslation('common')(Sidebar));