import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import reducer from '../reducers';

export default function configureAppStore(initialState) {
  const middleware = [];

  if (process.env.NODE_ENV !== 'production') {
    middleware.push(logger);
  }

  const store = configureStore({
    reducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
    devTools: process.env.NODE_ENV !== 'production',
  });

  return store;
}
