import React, { Component } from 'react';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { withRouter } from "react-router";
// Connect react and redux
import { connect } from 'react-redux';
// Language translate import
import { withTranslation } from 'react-i18next';
// Action call from redux
import { reset, getAllCase } from '../../redux/actions/UserActions';
import Loading from '../Loader/Loader';
import { errorToast } from '../Notification/Notification';
import ErrorPage from '../Error/ErrorPage';
// check client Authentication
import { checkClientAuthentication, checkOnBoardRequiredForPages } from '../../utils/restriction';

class CaseListing extends Component {
    constructor(props) {
        super(props)

        this.state = {
            caseList: []
        }
    }

    componentWillMount() {
        this.props.reset();
        const check = checkOnBoardRequiredForPages(this.props);
        if (!check) {
            // check client Authentication
            const clinetAuthentication = checkClientAuthentication();
            if (!clinetAuthentication) {
                this.props.history.push('/Dashboard')
            } else {
                const userId = JSON.parse(localStorage.getItem("UserInfo")).userId;
                const allCaseParams = `?userId=${userId}`;
                this.props.getAllCase(allCaseParams);
            }
        }
    }


    componentWillReceiveProps(nextprops) {
        if (Object.keys(nextprops.allcase).length > 0) {
            const allCase = nextprops.allcase;
            if (allCase.Success) {
                this.setState({
                    caseList: allCase.Result.LegalCases
                })
            } else {
                errorToast(allCase.Message)
            }
        }
    }

    handleViewDetails = (id) => {
        this.props.history.push({
            pathname: '/Casedetails',
            state: {
                caseId: id
            }
        })
    }

    render() {
        if (this.props.fetching && !this.props.error) {
            return (<Loading />)
        }
        return (
            <div className="Dashboard">
                <Header></Header>
                <div className="main-container">
                    <Sidebar />
                    {this.props.error ? <ErrorPage /> :
                        <div className="content-area-details" style={{ alignContent: "flex-start" }}>
                            {this.state.caseList.length == 0 ?
                                <div style={{ width: "100%", textAlign: "center" }}>
                                    <h3>{this.props.t('caseList.noCase')}</h3>
                                </div> :
                                this.state.caseList.sort(function (a, b) {
                                    if (window.globalConfig.CustomerName === "Strandenæs") {
                                        var textA = a.Title.toUpperCase();
                                        var textB = b.Title.toUpperCase();
                                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                    }
                                }).map((key, i) => (
                                    <div className="col-md-12" key={i}>
                                        <h1 className="ml-3 mb-3 case-title">{key.Title}</h1>
                                        <div className="item first-item">
                                            <p className="mb-3">{key.Description}</p>
                                            <h3 className="mb-2"> {this.props.t('caseList.projectDetails')}
                                                <span className="unOpened-messages ml-3"><FontAwesomeIcon icon={faCommentDots} /> <span>{key.UnReadMessageCount}</span></span>
                                                <span className="unOpened-document"><FontAwesomeIcon icon={faFileAlt} /><span>{key.UnOpenDocumentCount}</span></span></h3>
                                            <ul className="d-flex mb-4">
                                                <li className="case-point-list"><span className="d-block">{this.props.t('caseList.legalArea')}</span>
                                                    {key.LegalArea}</li>
                                                <li className="case-point-list"><span className="d-block">{this.props.t('caseList.legalBranch')}</span>
                                                    {key.LegalBranch}</li>
                                            </ul>
                                            
                                            {window.globalConfig.CustomerName !== "Kreftforeningen" &&
                                            <h3 className="mb-2">{this.props.t('caseList.lawyer')}</h3> && 
                                                key.Lawyers.map((Lkey, i) => (
                                                    
                                                            <ul key={i} className="d-flex mb-0">
                                                                <li className="case-point-list case-lawyer mb-2">
                                                                    <img src={Lkey.image} alt="" />
                                                                </li>
                                                                <li className="case-point-list">
                                                                    <span className="d-block">{this.props.t('caseList.lawyerName')}</span>
                                                                    {Lkey.name}
                                                                </li>
                                                                <li className="case-point-list">
                                                                    <span className="d-block">{this.props.t('caseList.lawyerMobile')}</span>
                                                                    {Lkey.phone}
                                                                </li>
                                                                <li className="case-point-list">
                                                                    <span className="d-block">{this.props.t('caseList.lawyerEmail')}</span>
                                                                    <a href={"mailto:"+Lkey.email}>{Lkey.email}</a>
                                                                </li>
                                                            </ul>                                    
                                                ))
                                            }
                                            <div style={{ textAlign: "right" }}>
                                                <Button
                                                    className="btn rounded primarybtn pt-2 pb-2 pl-4 text-uppercase pr-4"
                                                    onClick={() => { this.handleViewDetails(key.Id) }}
                                                >
                                                    {window.globalConfig.CustomerName === "Strandenæs" ? "Åpne dokumenter" : this.props.t('caseList.viewButton')}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allcase: state.user.caseList,
    fetching: state.user.fetching,
    error: state.error.isError
})

const mapDispatchToProps = {
    getAllCase,
    reset
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(CaseListing)));