import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
// Language translate import
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import './../../assets/css/Dashboard.css';
class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: props.userName?props.userName:JSON.parse(localStorage.getItem("UserInfo")).name,
            image: localStorage.getItem("UserImage"),
            portallogo: localStorage.getItem("portalLogo")
        }

    }

    componentWillMount() {

        this.setState({
            image: localStorage.getItem("UserImage"),
            surveyCount: localStorage.getItem("surveycount") ? localStorage.getItem("surveycount") : 0,
            portallogo: localStorage.getItem("portalLogo")
        })
    }

    handleSurveyList = () => {
        this.props.history.push('SurveyList')
    }

    render() {
        return (
            <div className={"header-"+window.globalConfig.CustomerName+" container-fluid"}>
                <div className="row align-items-center">
                    <div className="col-6 d-flex">
                        <div className="logo">
                            <Link to="/"><img src={this.state.portallogo} alt="Legal365" height="100%" /></Link>
                        </div>
                    </div>
                    <div className="col-6 text-right userarea">
                        <div className="surveyheadicon">
                            <a style={{ cursor: "pointer" }} onClick={this.handleSurveyList}>
                                <FontAwesomeIcon className="surveylisticon" icon={faClipboardList} />
                                <span>{this.state.surveyCount}</span>
                            </a>
                        </div>
                        <div className="userimg"><img src={this.state.image} alt="" /></div>
                        {/* {this.props.t('header.welcome')}  */}
                        <Dropdown>
                            <Dropdown.Toggle className="usernameDropdown" id="dropdown-basic">
                                <span>{this.state.name}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="/" onClick={() => { sessionStorage.clear() }}>{this.props.t('header.logOut')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withTranslation('common')(Header));
