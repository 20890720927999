import React, { Component } from 'react'


import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { Button } from 'react-bootstrap';
import { withRouter } from "react-router";
// Connect react and redux
import { connect } from 'react-redux';
// Action call from redux
import { news } from '../../redux/actions/NewsAction';
//Moment
import moment from 'moment';
// Language translate import
import { withTranslation } from 'react-i18next';
import { checkOnBoardRequiredForPages } from '../../utils/restriction';
import Loading from '../Loader/Loader';
import ErrorPage from '../Error/ErrorPage';
import { errorToast } from '../Notification/Notification';
import { reset } from '../../redux/actions/UserActions';
import { dateTimeFormat } from '../../config/ApiConfig';
import './../../assets/css/Dashboard.css';
class News extends Component {
    constructor(props) {
        super(props)

        this.state = {
            news: [],
            otherNews: []
        }
    }

    componentWillMount() {
        

        this.props.reset();
        const check = checkOnBoardRequiredForPages(this.props);
        if (!check) {
            this.props.news();
        }
    }

    componentWillReceiveProps(nextprops) {
        if (Object.keys(nextprops.newsdata).length > 0) {
            const newsData = nextprops.newsdata;
            if (newsData.Success) {
                this.setState({
                    news: newsData.Result.TopNews,
                    otherNews: newsData.Result.OtherNews,
                })
            } else {
                errorToast(newsData.Message)
            }
        }
    }

    render() {
        return (
            <div className="Dashboard">
                {this.props.fetching && <Loading />}
                <Header></Header>
                <div className="main-container">
                    <Sidebar />
                    {this.props.error ? <ErrorPage /> :
                        <div className="content-area">
                            <div className="row">
                                <h2 className="col-md-12 pagettl mb-3">{this.props.t('news.title')}</h2>
                                {
                                    this.state.news.map((key, i) => (
                                        <div className="col-12 col-md-12 col-lg-6" key={i}>
                                            <div className="newsblock">
                                                <p className="newsimg"><img src={key.Image} alt="News" /></p>
                                                <p className="timestamp mb-0">{moment(key.PublishFromDate).format(dateTimeFormat.dateFormat)}</p>
                                                <h3><a href={key.Hyperlink} target="_blank">{key.Description}</a></h3>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>


                            <div className="row multipleNews">
                                {
                                    this.state.otherNews.map((key, i) => (
                                        <div className="col-12 col-md-6 col-lg-4" key={i}>
                                            <div className="newsblock">
                                                <p className="newsimg"><img src={key.Image} alt="News" /></p>
                                                <p className="timestamp mb-0">{moment(key.PublishFromDate).format(dateTimeFormat.dateFormat)}</p>
                                                <h3><a href={key.Hyperlink} target="_blank">{key.Description}</a></h3>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                            <div className="row pt-5">
                                <div className="col-12 d-flex align-items-center justify-content-center">
                                    <Button className="btn btn-primary generalbtn pl-5 pr-5">{this.props.t('news.loadMoreButton')}</Button>
                                </div>
                            </div>

                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    newsdata: state.news.news,
    fetching: state.news.fetching,
    error: state.error.isError
})

const mapDispatchToProps = {
    news,
    reset
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(News)));