import React, { Component } from 'react';
// Language translate import
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import {successToast } from '../../Notification/Notification';
class StepFour extends Component {

  constructor(props) {
    super(props)

    this.state = {
      message: localStorage.getItem("bookingConfirmation")? localStorage.getItem("bookingConfirmation"):"Vi har nå mottatt din henvendelse og vil ta kontakt så snart som mulig"
    }
  }
  
  componentDidMount() {
      successToast(this.state.message);
  }

  render() {
    return (
      <div>
        {/* Step 4 */}
        <h2 className="pagettlSmall">{this.props.t('booking.confirmationTitle')}</h2>

        <div className="box-container progressform verifyinfo mb-2">
          <p>{this.state.message}</p>
        </div>
      </div>
    )
  }
}

export default withRouter(withTranslation('common')(StepFour));
