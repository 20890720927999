import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Surveytooltip from './SurveyTooltip';
import SurveyRequired from './SurveyRequired';

export default class SurveySingleChoice extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedValue: []
        }
    }

    handleOnChangeValues = (e) => {
        let selectedValue=[];
        selectedValue.push(e.target.value);
        this.setState({
            selectedValue: selectedValue
        }, () => { this.props.setUpdateValue({ "required": this.props.data.isMandatoryQuestion, "data": this.state.selectedValue }, this.props.uid) })
    }

    render() {
        return (
            <Form.Group
                onChange={this.handleOnChangeValues}
            >
                <div className="surveyquestions"  id={this.props.data.relatedToQuestionId?this.props.data.relatedToQuestionId:""}>
                    <p>{this.props.data.question}{this.props.data.isMandatoryQuestion ? <SurveyRequired /> : ""}
                        {this.props.data.questionTooltip != "" ? <Surveytooltip text={this.props.data.questionTooltip} /> : ""}
                    </p>
                    {this.props.data.multipleChoiceOptions.map((key, i) => (
                        <Form.Check key={i}
                            type="radio"
                            label={key.trim()}
                            name="single"
                            id={`formHorizontalRadios${i}${key}`}
                            value={key.trim()}
                        />
                    ))}
                </div>
            </Form.Group>
        )
    }
}
