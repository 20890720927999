import React, { Component } from 'react'
import EmptyHeader from '../Header/EmptyHeader'
import { withRouter } from "react-router";
// Language translate import
import { withTranslation } from 'react-i18next';
// Connect react and redux
import { connect } from 'react-redux';
// Action call from redux
import { reset, getPublicSurvey, getPortalLogo, postSurvey } from '../../redux/actions/UserActions';
import Loading from '../Loader/Loader';
import ErrorPage from '../Error/ErrorPage'
import RenderSurvey from './RenderSurvey';
import { errorToast, successToast } from '../Notification/Notification';

class PublicSurvey extends Component {
    constructor(props) {
        super(props)
        this.state = {
            question: [],
            survey: {},
            requireQuestionCheck: [],
            surveyExist: false,
        }
    }
    componentDidMount() {
        const getSurveyParams = `?uniqueCode=${this.props.match.params.id}`;
        this.props.getPublicSurvey(getSurveyParams)

        //Api call for Logo
        this.props.getPortalLogo();
    }

    componentWillReceiveProps(nextprops) {
        if (nextprops.survey !== this.props.survey) {
            const surveyData = nextprops.survey;
            if (surveyData.Success) {
                if (surveyData.Result.surveyId != "00000000-0000-0000-0000-000000000000") {
                    this.setState({
                        surveyExist: true,
                        survey: surveyData.Result,
                        question: surveyData.Result.LegalSurveyTemplates
                    }, () => { delete this.state.survey.LegalSurveyTemplates; this.addRequireQuestionCheckArr(); })
                }
            } else {
                errorToast(surveyData.Message)
            }
        }
        if (nextprops.savesurvey !== this.props.savesurvey) {
            const savesurveyData = nextprops.savesurvey;
            this.props.history.push("/PublicSurveySuccess");
            if (savesurveyData.signingUrl) {
                window.location.href = savesurveyData.signingUrl;
            }
            else if (savesurveyData.updatesurveyinvitation) {
                successToast(this.props.t("survey.successSurvey"));
            }
            else {
                errorToast(savesurveyData.Message)
            }
        }
    }

    addRequireQuestionCheckArr = () => {
        const updateArr = []
        this.state.question.forEach(element => {
            if (element.relatedToQuestionId !== "00000000-0000-0000-0000-000000000000") {
                var ele = document.getElementsByName(element.relatedToQuestionId);
                ele.forEach((e) => {
                    if (e.checked) {
                        if (e.value === "Yes" && element.isMandatoryQuestion)
                            updateArr.push(element.isMandatoryQuestion)
                    }
                });
            }
            else {
                updateArr.push(element.isMandatoryQuestion)
            }
        });
        this.setState({
            requireQuestionCheck: updateArr,
        })
    }

    render() {
        if (this.props.fetching && !this.props.error) {
            return (<Loading />)
        }
        return (
            <div className="Dashboard">
                <EmptyHeader name={this.state.survey.invitationName} />
                <div className="main-container">
                    {this.props.error ? <ErrorPage /> :
                        !this.state.surveyExist ? <ErrorPage /> :
                            <RenderSurvey survey={this.state.survey} question={this.state.question} requireQuestionCheck={this.state.requireQuestionCheck} isPublicSurvey={true} />
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    survey: state.user.getPublicSurvey,
    savesurvey: state.user.postSurvey,
    fetching: state.user.fetching,
    error: state.error.isError,
    portalLogo: state.user.portalLogo,
})

const mapDispatchToProps = {
    getPublicSurvey,
    postSurvey,
    getPortalLogo,
    reset
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(PublicSurvey)));