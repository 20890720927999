import React, { Component } from 'react';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { withRouter } from "react-router";
import { callback } from '../../redux/actions/UserActions';
import { connect } from 'react-redux';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import ErrorPage from '../Error/ErrorPage';

class SurveyCallback extends Component {
    constructor(props) {
        super(props)
        this.state = {
            portallogo: localStorage.getItem("portalLogo"),
            languageCode: localStorage.getItem("language"),
            i18props: props.i18n,
            genericError: false
        }
    }
    componentDidMount() {
        let authorizationResponse = {
            Code: "",
            SurveyId: ""
        }
        const urlParams = this.props.location.search;
        if (urlParams) {
            const queryparam =  new URLSearchParams(urlParams);
            if (queryparam) {
                authorizationResponse.SurveyId = queryparam.get('state');
                this.setState({
                    SurveyId: queryparam.get('state')
                });
            }
            if (queryparam) {
                authorizationResponse.Code = queryparam.get('code');
            }
            else {
                this.setState({
                    genericError: true
                })
            }
        }
        this.props.callback(authorizationResponse);
    }

    componentDidUpdate(prevProps) {
        if (this.props.Callback !== prevProps.Callback) {
            this.handleLanguageChange();
        }
    }

    handleLanguageChange = () => {
        const languageCode = this.state.languageCode
        if (languageCode == 1033) {
            this.props.i18n.changeLanguage('en');
            localStorage.setItem('language', 'en');

        }
        else if (languageCode == 1044) {
            this.props.i18n.changeLanguage('no');
            localStorage.setItem('language', 'no');
        }
    }
    render() {
        return (
            <div className="Dashboard">
                {!localStorage.getItem('publicsurveyuser') && <Header/>}
                <div className="main-container">
                   {!localStorage.getItem('publicsurveyuser') && <Sidebar />}
                    {this.state.genericError ? <ErrorPage /> :
                        <div className="successmessage">
                            <h1 className="display-3">{this.props.t('esign.thankyouMsg')}</h1>
                            <FontAwesomeIcon className="successicon" icon={faCheck} />
                            <p>{this.props.t('esign.documentSignerMsg')}</p>
                        </div>
                    }
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    Callback: state.user.callback,
    fetching: state.user.fetching,
    error: state.error.isError,
    responseData: state.user.responseCallback
})

const mapDispatchToProps = {
    callback
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(SurveyCallback)));
