import React, { Component } from 'react'
// Language translate import
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import placeholder from '../../assets/images/placeholder.png'
import './../../assets/css/Dashboard.css';

class EmptyHeader extends Component {
    constructor(props) {
        super(props)

        this.state = {
            appLogo: localStorage.getItem("publicportallogo"),
            name: localStorage.getItem("publicsurveyuser")
        }
    }
    render() {
        return (
            <div className={"header-"+window.globalConfig.CustomerName+" container-fluid"}>
                <div className="row align-items-center">
                    <div className="col-6 d-flex">
                        <div className="logo">
                            <Link to="/"><img src={this.state.appLogo == "" ? placeholder : this.state.appLogo} alt="Legel365" height="100%" /></Link>
                        </div>
                    </div>
                    {!this.props.name ? <div></div> :
                        <div className="col-6 text-right userarea">
                            {this.props.t('header.welcome')} , {this.props.name}
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(withTranslation('common')(EmptyHeader));
