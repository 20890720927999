import {
    LOGIN_NEWS_REQUEST,
    LOGIN_NEWS_SUCCESS,
    LOGIN_NEWS_FAIL,
    DASHBOARD_NEWS_REQUEST,
    DASHBOARD_NEWS_SUCCESS,
    DASHBOARD_NEWS_FAIL,
    NEWS_REQUEST,
    NEWS_SUCCESS,
    NEWS_FAIL,
    ISERRORSERVER
} from '../constants/App';
import {endPoints} from '../../config/ApiConfig';
import { get } from "./../../services/RestAPIWrapper";

// Login news action
function loginNewsRequest() {
    return {
        type: LOGIN_NEWS_REQUEST
    }
}

function loginNewsReceiveData(json) {
    return {
        type: LOGIN_NEWS_SUCCESS,
        data: json
    }
}

export function loginNews() {

    return dispatch => {
        dispatch(loginNewsRequest());

        return get(endPoints.loginNews.endPoint, '', json => {
                if (json.Success == 'false') {
                    return dispatch({ type: LOGIN_NEWS_FAIL, data: json });
                } else {
                    return (dispatch(loginNewsReceiveData(json)));
                }
        	},err => {
                console.error(err);
                return dispatch(errorServer(err));
            });
    };
}


// Dashboard news action
function dashBoardNewsRequest() {
    return {
        type: DASHBOARD_NEWS_REQUEST
    }
}

function dashBoardNewsReceiveData(json) {
    return {
        type: DASHBOARD_NEWS_SUCCESS,
        data: json
    }
}

export function dashBoardNews() {

    return dispatch => {
        dispatch(dashBoardNewsRequest());

        return get(endPoints.dashBoardNews.endPoint, '',json => {
                if (json.Success == 'false') {
                    return dispatch({ type: DASHBOARD_NEWS_FAIL, data: json });
                } else {
                    return (dispatch(dashBoardNewsReceiveData(json)));
                }
        	}, err => {
                console.error(err);
                return dispatch(errorServer(err));
            });
    };
}



// Login news action
function newsRequest() {
    return {
        type: NEWS_REQUEST
    }
}

function newsReceiveData(json) {
    return {
        type: NEWS_SUCCESS,
        data: json
    }
}

export function news() {

    return dispatch => {
        dispatch(newsRequest());

        return get(endPoints.news.endPoint, '',json => {
                if (json.Success == 'false') {
                    return dispatch({ type: NEWS_FAIL, data: json });
                } else {
                    return (dispatch(newsReceiveData(json)));
                }
        	},err => {
                console.error(err);
                return dispatch(errorServer(err));
            });
    };
}



// Error 
export function errorServer(error) {
	return {
        type: ISERRORSERVER,
        data: error
	}
}