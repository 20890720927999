const emailReg = /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/,
    passwordReg = /[a-zA-ZæøåÆØÅ0-9@.\\-~!@#$%^;:&?*-=+]{6,64}/;

class FormValidation {

    validateEmail(value) {
        return emailReg.test(value);
    }
    
    validatePassword(value) {
        return passwordReg.test(value);
    }

}

export default new FormValidation();
