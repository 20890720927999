import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import Surveytooltip from './SurveyTooltip';
import SurveyRequired from './SurveyRequired';
import { dateTimeFormat } from '../../config/ApiConfig';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
export default class SurveyDatepicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Date: new Date()

        }
    }

    handleChange = (date) => {
        this.setState({
            Date: date
        });
        if(date !== null){
            this.props.setUpdateDate({"required":this.props.data.isMandatoryQuestion,"data":moment(date).format(dateTimeFormat.dateOnlyFormat)},this.props.uid)
        }else{
            this.props.setUpdateDate({"required":this.props.data.isMandatoryQuestion,"data":date},this.props.uid)
        }
    };
    render() {
        return (
            <Form.Group>
                <div className="surveyquestions"  id={this.props.data.relatedToQuestionId?this.props.data.relatedToQuestionId:""}>
                    <p>{this.props.data.question}{this.props.data.isMandatoryQuestion ? <SurveyRequired /> : ""}
                        {this.props.data.questionTooltip != "" ? <Surveytooltip text={this.props.data.questionTooltip} /> : ""}
                    </p>
                    <div className="row">
                        <div className="col-lg-6 col-sm-6">
                            <div className="Surveydatecontainer">
                                <DatePicker
                                    selected={this.state.Date}
                                    onChange={this.handleChange}
                                    shouldCloseOnSelect={false}
                                    dateFormat={"dd.MM.yyyy"}
                                    className="surverydatetimepicker"
                                    showYearDropdown
                                    showMonthDropdown
                                />
                                <div className="calendericon"><FontAwesomeIcon icon={faCalendar} /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form.Group>
        )
    }
}
