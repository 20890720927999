import React, { Component } from 'react'
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { withRouter } from "react-router";
import RenderSurvey from './RenderSurvey';
// Language translate import
import { withTranslation } from 'react-i18next';
// check client Authentication
import { checkClientAuthentication, checkOnBoardRequiredForPages } from '../../utils/restriction';
// Connect react and redux
import { connect } from 'react-redux';
// Action call from redux
import { reset, getSurvey, postSurvey } from '../../redux/actions/UserActions';
import Loading from '../Loader/Loader';
import { errorToast, successToast, infoToast } from '../Notification/Notification';
import ErrorPage from '../Error/ErrorPage';

class Survey extends Component {
    constructor(props) {
        super(props)
        this.state = {
            amount: [],
            date: [],
            textarea: [],
            singlelinetext: [],
            boolean: [],
            multipleChoice: [],
            question: [],
            survey: {},
            requireQuestionCheck: [],
            requireFields: false
        }
    }
    componentWillMount() {
        const check = checkOnBoardRequiredForPages(this.props);
        if (!check) {
            // check client Authentication
            const clinetAuthentication = checkClientAuthentication();
            if (!clinetAuthentication) {
                this.props.history.push('/SurveyList')
            } else {
                const surveyId = new URLSearchParams(this.props.location.search);
                if (surveyId.has("SurveyID")) {
                    const clientId = JSON.parse(localStorage.getItem("UserInfo")).userId;
                    const getSurveyParams = `?clientId=${clientId}&surveyId=${surveyId.get("SurveyID")}`;
                    this.props.getSurvey(getSurveyParams)
                } else {
                    if (!this.props.location.state) {
                        this.props.history.push('/SurveyList')
                    } else {
                        const clientId = JSON.parse(localStorage.getItem("UserInfo")).userId;
                        const getSurveyParams = `?clientId=${clientId}&surveyId=${this.props.location.state.surveyId}`;
                        this.props.getSurvey(getSurveyParams)
                    }
                }
            }
        }
    }

    componentWillReceiveProps(nextprops) {
        if (nextprops.survey !== this.props.survey) {
            const surveyData = nextprops.survey;
            if (surveyData.Success) {
                this.setState({
                    survey: surveyData.Result,
                    question: surveyData.Result.LegalSurveyTemplates
                }, () => { delete this.state.survey.LegalSurveyTemplates; this.addRequireQuestionCheckArr(); })
            } else {
                errorToast(surveyData.Message)
            }
        }
        if (nextprops.savesurvey !== this.props.savesurvey) {
            const savesurveyData = nextprops.savesurvey;
            this.props.history.push("/SurveyList");
            if (savesurveyData.signingUrl) {
                window.location.href = savesurveyData.signingUrl;
            }
            else if (savesurveyData.updatesurveyinvitation) {
                successToast(this.props.t("survey.successSurvey"));
            }
            else {
                errorToast(savesurveyData.Message)
            }
        }
    }

    addRequireQuestionCheckArr = () => {
        const updateArr = []
        this.state.question.forEach(element => {
            if (element.relatedToQuestionId !== "00000000-0000-0000-0000-000000000000") {
                var ele = document.getElementsByName(element.relatedToQuestionId);
                ele.forEach((e) => {
                    if (e.checked) {
                        if (e.value ==="Yes" && element.isMandatoryQuestion)
                            updateArr.push(element.isMandatoryQuestion)
                    }
                });
            }
            else {
                updateArr.push(element.isMandatoryQuestion)
            }
        });
        this.setState({
            requireQuestionCheck: updateArr,
        })
    }

    handleSkip = () => {
        if (this.state.survey.isRequired) {
            infoToast(this.props.t('survey.skipText'))
        } else {
            this.props.history.push("/SurveyList")
        }
    }

    render() {
        if (this.props.fetching && !this.props.error) {
            return (<Loading />)
        }
        return (
            <div className="Dashboard">
                <Header></Header>
                <div className="main-container">
                    <Sidebar />
                    {this.props.error ? <ErrorPage /> :
                        <RenderSurvey survey={this.state.survey} question={this.state.question} requireQuestionCheck={this.state.requireQuestionCheck} isPublicSurvey={false} />
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    survey: state.user.getSurvey,
    savesurvey: state.user.postSurvey,
    fetching: state.user.fetching,
    error: state.error.isError
})

const mapDispatchToProps = {
    getSurvey,
    postSurvey,
    reset
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Survey)));