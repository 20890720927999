import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const SurveyTooltip = (props) => {
    return (
        <OverlayTrigger
            key={"top"}
            placement={"bottom"}
            overlay={
                <Tooltip id="overlay-example">
                   {props.text}
                </Tooltip>
            }
        >
            <FontAwesomeIcon className="surveyinfoicon" icon={faInfoCircle} />
        </OverlayTrigger>
    )
}

export default SurveyTooltip;
