import React, { Component } from 'react'
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { withRouter } from "react-router";
// Connect react and redux
import { connect } from 'react-redux';
// Action call from redux
import { dashBoardNews } from '../../redux/actions/NewsAction';
import { reset } from '../../redux/actions/UserActions';
//Moment
import moment from 'moment';
// Language translate import
import { withTranslation } from 'react-i18next';
// check client Authentication
import { checkClientAuthentication, checkOnBoardRequiredForPages } from '../../utils/restriction';
import Loading from '../Loader/Loader';
import ErrorPage from '../Error/ErrorPage';
import { errorToast } from '../Notification/Notification';
import { dateTimeFormat } from '../../config/ApiConfig';
import './../../assets/css/Dashboard.css';

class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            news: [],
            checkClientAuthentication: true,
            introMessage: ""
        }
    }
    componentWillMount() {

        this.setState({
            introMessage: localStorage.getItem("dashBoardMessage") ? localStorage.getItem("dashBoardMessage") : "Få gratis 30 minutter konsultasjon",
        })
        this.props.reset();
        const check = checkOnBoardRequiredForPages(this.props);
        if (!check) {
            this.props.dashBoardNews();

            const clinetAuthentication = checkClientAuthentication();
            if (clinetAuthentication) {
                this.setState({
                    checkClientAuthentication: true
                })
            } else {
                this.setState({
                    checkClientAuthentication: false
                })
            }
        }
    }

    componentWillReceiveProps(nextprops) {
        if (Object.keys(nextprops.dashboardnews).length > 0) {
            const newsData = nextprops.dashboardnews;
            if (newsData.Success) {
                this.setState({
                    news: newsData.Result.TopNews
                })
            } else {
                errorToast(newsData.Message)
            }
        }
    }
    render() {
        return (
            <div className="Dashboard">
                {this.props.fetching && <Loading />}
                <Header></Header>
                <div className="main-container">
                    {/* "sidebarOpen" class is already created we just need toggle the class on click of the toggle button added to the header component  */}
                    <Sidebar />
                    {this.props.error ? <ErrorPage /> :
                        <div className="content-area">
                            {
                                this.state.checkClientAuthentication ?

                                    <div className={"banner-" + window.globalConfig.CustomerName + " mb-4"}>
                                        <div className="innerbaner">
                                            <h1 style={window.globalConfig.CustomerName === "Strandenæs" ? { color: "black" } : {}}>{this.state.introMessage}</h1>
                                            {window.globalConfig.CustomerName !== "Osloadvokatene" && <button style={window.globalConfig.CustomerName === "Strandenæs" ? { color: "black", border: "1px solid black" } : {}} onClick={() => { window.globalConfig.CustomerName === "Kreftforeningen" ? this.props.history.push('Inquiry') : window.globalConfig.CustomerName === "Strandenæs" ? this.props.history.push('CaseList') : this.props.history.push('Booking') }}>{window.globalConfig.CustomerName === "Kreftforeningen" ? "Ny sak" : window.globalConfig.CustomerName === "Strandenæs" ? "Innhold" : this.props.t('dashBoard.bookButton')}</button>}
                                        </div>
                                    </div>
                                    :
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <p>{localStorage.getItem("unauthorisedErrorMessage") ? localStorage.getItem("unauthorisedErrorMessage"):this.props.t('dashBoard.unAuthoriseMessage')}</p>
                                        </div>
                                    </div>
                            }
                            {window.globalConfig.CustomerName !== "Kreftforeningen" && window.globalConfig.CustomerName !== "Osloadvokatene" &&
                                <div className="row">
                                    <h2 className={window.globalConfig.CustomerName === "Strandenæs" ? "col-md-12 mb-3 pagettl-" + window.globalConfig.CustomerName : "col-md-12 pagettl mb-3"}>{this.props.t('dashBoard.newsTitle')}</h2>
                                    {
                                        this.state.news.map((key, i) => (
                                            <div className="col-12 col-md-12 col-lg-6" key={i}>
                                                <div className="newsblock">
                                                    <p className="newsimg"><img src={key.Image} alt="News" /></p>
                                                    <p className="timestamp mb-0">{moment(key.PublishFromDate).format(dateTimeFormat.dateFormat)}</p>
                                                    <h3><a href={key.Hyperlink} target="_blank">{key.Description}</a></h3>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    dashboardnews: state.news.dashBoardNews,
    fetching: state.news.fetching,
    error: state.error.isError
})

const mapDispatchToProps = {
    dashBoardNews,
    reset
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Dashboard)));
