import axios from 'axios';
import { apiPath, headers, baseURL } from '../config/ApiConfig';


export function get(endPoint, inputParam, sucessCallback, errorCallback) {
    return axios.get(`${apiPath}${endPoint}${inputParam}`, headers, { timeout: 20000 })
        	.then(response => response.data)
        	.then(sucessCallback)
            .catch(errorCallback);
 }

 export function getVippsAuthInfo(endPoint, sucessCallback, errorCallback) {
    return axios.get(`${endPoint}`, { timeout: 20000 })
        	.then(response => response.data)
        	.then(sucessCallback)
            .catch(errorCallback);
 }

 export function downloadDocument(downloadUrl, responseType, successCallback, errorCallback)
 {
   return axios.get(`${baseURL}${downloadUrl}`, { responseType: responseType, headers : headers.headers  }, { timeout: 20000 })
    .then(successCallback)
    .catch(errorCallback);
 }

 export function post(endPoint, inputParam, sucessCallback, errorCallback) {
    return axios.post(`${apiPath}${endPoint}`,{...inputParam}, headers, { timeout: 20000 })
        	.then(response => response.data)
        	.then(sucessCallback)
            .catch(errorCallback);
 }

 