import React, { Component } from 'react'
import Button from 'react-bootstrap/Form';
// Language translate import
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
// Connect react and redux
import { connect } from 'react-redux';
// Action call from redux
import { bookingSave } from '../../../redux/actions/UserActions';
import Loading from '../../Loader/Loader';
import { errorToast } from '../../Notification/Notification';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
let tempthis;
class StepThree extends Component {

    constructor(props) {
        super(props);

        this.state = {
            subject: props.bookingData.step1 ? props.bookingData.step1.subject : "",
            description: props.bookingData.step1 ? props.bookingData.step1.description : "",
            time: props.bookingData.step1 ? props.bookingData.step1.time : "",
            legalBranch: props.bookingData.step1 ? props.bookingData.step1.legalBranch : "",
            legalArea: props.bookingData.step1 ? props.bookingData.step1.legalArea : "",
            relationShip: props.bookingData.step1 ? props.bookingData.step1.relationShip : "",
            lawyerData: props.bookingData.step2 ? props.bookingData.step2.lawyer : [],
            selectedLawyer: props.bookingData.step2 ? props.bookingData.step2.selectedLawyer : "",
            selectedAreaName: props.bookingData.step1 ? props.bookingData.step1.selectedAreaName : "",
            selectedRelationShip: props.bookingData.step1 ? props.bookingData.step1.selectedRelationShip : "",
            selectedBranchName: props.bookingData.step1 ? props.bookingData.step1.selectedBranchName : "",
            phoneNumber: "",
            name: "",
            documents: [],
            files: [],
            validtoUpload:true
        }

        tempthis = this;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }
    onDrop = (files) => {
        let documentArray = [];
        files.forEach(file => {
            const document = {}
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                let base64string = reader.result;
                base64string = base64string.split(",").pop();
                document.fileName = file.name;
                document.base64String = base64string;
                documentArray.push(document);
                tempthis.setState({
                    documents: documentArray
                })
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        });
        this.validateFiles(files);
        this.setState({ files });
    };
    componentWillMount() {
        const userInfo = JSON.parse(localStorage.getItem("UserInfo"));
        this.setState({
            phoneNumber: userInfo.phone_number,
            name: userInfo.name
        })
    }

    handleSubmit = () => {
        let files = this.state.files;
        const bookingData = {
            subject: this.state.subject,
            description: this.state.description,
            time: this.state.time,
            areaId: this.state.legalArea,
            relationshipId:this.state.relationShip,
            branchId: this.state.legalBranch,
            documents: this.state.documents,
            dynamicsEntity: localStorage.getItem("dynamicsEntity"),
            clientId :JSON.parse(localStorage.getItem("UserInfo")).userId
        }
        if (this.state.selectedLawyer != "" && this.state.selectedLawyer != undefined) {
            bookingData.lawyerId = this.state.selectedLawyer
        }
        
        if (this.state.validtoUpload) {
            this.props.bookingSave(bookingData);          
        }
        else
        {
            errorToast(this.props.t('booking.fileSizeMessage'));
        }
        
    }

    handleBack = () => {
        window.globalConfig.CustomerName === "Kreftforeningen" ? this.props.goToStep2(1) : this.props.goToStep2(2);
    }

    remove = (fileName) => {
        var filteredArray = this.state.files.filter(e => e.name !== fileName);
        this.setState({
            files: filteredArray
        })
        this.validateFiles(filteredArray);
    }
    componentWillReceiveProps(nextprops) {
        if (Object.keys(nextprops.booking).length > 0) {
            const bookingData = nextprops.booking;
            if (bookingData.Success) {
                this.props.bookMessage(bookingData.Message);
                this.props.next();
            } else {
                errorToast(this.props.t('booking.submitError'))
                window.location.reload()
            }
        }
    }
    validateFiles = (files) => {
        let totalFileSize=0;
        files.forEach(file => {
            let megabytes = (file.size / (1024 * 1024)).toFixed();
            totalFileSize= parseInt(totalFileSize)+parseInt(megabytes);         
        });
        if(totalFileSize>90)
        {
            this.setState({validtoUpload:false});
        }
        else
        {
            this.setState({validtoUpload:true});   
        }
    }
    render() {
        const files = this.state.files.map(file => (
            <tr key={file.name}>
                <td>
                    {file.name} - {file.size} bytes
                </td>
                <td>
                    <Button style={{ marginLeft: "150px", cursor: "pointer" }} onClick={() => this.remove(file.name)}><FontAwesomeIcon icon={faTrash} /></Button>
                </td>
            </tr>
        ));

        return (
            <div>
                {this.props.fetching && <Loading />}
                <h2 className="pagettlSmall">{this.props.t('booking.information')}</h2>
                <p>{this.props.t('booking.infoText')}</p>

                <div className="box-container progressform verifyinfo mb-2">

                    <div className="row">
                        <div className="col-md-12">
                            <label>{this.props.t('booking.subject')}</label>
                            <p>{this.state.subject}</p>
                        </div>
                        <div className="col-md-12">
                            <label>{this.props.t('booking.description')}</label>
                            <p>{this.state.description}</p>
                        </div>
                    </div>

                    <div className="row">
                        {this.state.time &&
                            <div className="col-md-4">
                                <label>{this.props.t('booking.time')}</label>
                                <p>{this.state.time}</p>
                            </div>
                        }
                        {this.state.selectedAreaName &&
                            <div className="col-md-4">
                                <label>{this.props.t('booking.legalArea')}</label>
                                <p>{this.state.selectedAreaName}</p>
                            </div>}
                        {this.state.selectedRelationShip &&
                            <div className="col-md-4">
                                <label>{this.props.t('booking.relationShip')}</label>
                                <p>{this.state.selectedRelationShip}</p>
                            </div>}
                        {this.state.selectedBranchName &&
                            <div className="col-md-4">
                                <label>{this.props.t('booking.legalBranch')}</label>
                                <p>{this.state.selectedBranchName}</p>
                            </div>
                        }
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <label>{this.state.lawyerData.length > 0 ? this.props.t('booking.infoLawyer') : ""}</label>
                            {
                                this.state.lawyerData.map((item, index) => (
                                    <div className="selctedlawyer" key={index}>
                                        <img src={item.image} alt="" />
                                        <div className="row ml-0">
                                            <div className="col-12 col-md-12 col-lg-4 mb-3">
                                                <h3>{this.props.t('booking.lawyerGridName')}</h3>
                                                <p>{item.name}</p>
                                                <p>{item.title}</p>
                                                <p>{item.email}</p>
                                                <p>{item.phone}</p>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-4">
                                                <h3>{this.props.t('booking.lawyerGridDescription')}</h3>
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="fileUploadLabel">
                        <label>{this.props.t('caseDetails.fileUploadLabel')}</label>
                    </div>
                    <div className="fileUploader mt-5">
                        <Dropzone
                            multiple={true}
                            isDragActive={true}
                            onDrop={this.onDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <section className="container">
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} />
                                        <p className='fileDrop'>{this.props.t('caseDetails.documentAddFiles')}</p>
                                    </div>
                                    <aside>
                                        <table><tbody>{files}</tbody></table>
                                    </aside>

                                </section>
                            )}
                        </Dropzone>
                    </div>
                    <div className="row text-center pt-3">
                        <Button className="generalbtn pl-3 pr-3 pt-2 pb-2 m-auto" type="button" onClick={this.handleBack}>
                            {this.props.t('booking.back')}
                        </Button>
                        <Button className="generalbtn pl-3 pr-3 pt-2 pb-2 m-auto" type="submit" onClick={this.handleSubmit}>
                            {this.props.t('booking.bookingSubmit')}
                        </Button>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    booking: state.user.booking,
    fetching: state.user.fetching,
    error: state.error.isError
})

const mapDispatchToProps = {
    bookingSave
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(StepThree)));
