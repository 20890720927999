import React, { Component } from 'react'


import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { Button } from 'react-bootstrap';
import { withRouter } from "react-router";
//Input validation
import FormValidation from "../../utils/inputValidation";
// Language translate import
import { withTranslation } from 'react-i18next';
// Bootstrap switch 
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
// check client Authentication
import { checkClientAuthentication, checkOnBoardRequiredForPages } from '../../utils/restriction';
// Connect react and redux
import { connect } from 'react-redux';
// Action call from redux
import { profile, profileSave, reset } from '../../redux/actions/UserActions';
import Loading from '../Loader/Loader';
import { errorToast, successToast } from '../Notification/Notification';
import ErrorPage from '../Error/ErrorPage';
// Change language dropdown
import ReactFlagsSelect from 'react-flags-select';

import './../../assets/css/Dashboard.css';
class Profile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            email: "",
            phoneNumber: "",
            image: "",
            consentSms: true,
            consentEmail: true,
            consentTelephone: true,
            fileError: false,
            defaultFile: "",
            emailFormat: true,
            invoiceEmail: "",
            street1: "",
            street2: "",
            city: "",
            country: "",
            zipcode: "",
            errorRequiredFields: false,
            language: localStorage.getItem('language'),
            onBoardTermsAndCondition :  localStorage.getItem("portalTermsUrl"),
            selected:'NO',
            defaultVippsConcent : JSON.parse(localStorage.getItem("UserInfo")).defaultVippsConcent
        }
    }

    componentWillMount() {
        this.props.reset();
        const check = checkOnBoardRequiredForPages(this.props);
        if (!check) {
            // check client Authentication
            const clinetAuthentication = checkClientAuthentication();
            if (!clinetAuthentication) {
                this.props.history.push('/Dashboard')
            } else {
                const phone = JSON.parse(localStorage.getItem("UserInfo")).phone_number;
                const entity = localStorage.getItem("dynamicsEntity");
                const clientId=JSON.parse(localStorage.getItem("UserInfo")).userId;
                const getProfileParams = `?phoneNumber=${phone}&dynamicsEntity=${entity}&clientId=${clientId}`;
                this.props.profile(getProfileParams);
            }
        }

    }

    componentWillReceiveProps(nextprops) {
        if (Object.keys(nextprops.getprofile).length > 0) {
            const getProfile = nextprops.getprofile;
            if (getProfile.Success) {
                const data = getProfile.Result;
                this.setState({
                    name: data.name,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                    image: data.image,
                    consentSms: this.state.defaultVippsConcent,
                    consentEmail: this.state.defaultVippsConcent,
                    consentTelephone: this.state.defaultVippsConcent,
                    defaultFile: data.image,
                    invoiceEmail: data.invoiceEmail,
                    street1: data.postAddress.street1,
                    street2: data.postAddress.street2,
                    city: data.postAddress.city,
                    country: data.postAddress.country,
                    zipcode: data.postAddress.postalCode,
                    language: localStorage.getItem('language'),
                })
                localStorage.setItem("UserImage", data.image);
            } else {
                errorToast(getProfile.Message)
            }
        }

        if (Object.keys(nextprops.saveprofile).length > 0) {
            const saveData = nextprops.saveprofile;
            if (saveData.Success) {

                this.props.reset();

                // call get profile api
                const phone = JSON.parse(localStorage.getItem("UserInfo")).phone_number;
                const entity = localStorage.getItem("dynamicsEntity");
                const clientId=JSON.parse(localStorage.getItem("UserInfo")).userId;
                const getProfileParams = `?phoneNumber=${phone}&dynamicsEntity=${entity}&clientId=${clientId}`;
                this.props.profile(getProfileParams);
                // success message
                successToast(saveData.Message);

            } else {
                // error message
                errorToast(saveData.Message)
            }
        }

    }

    handlerFileChanged = (event) => {
        var file = event.target.files[0];
        if (file !== undefined) {
            if (/\.(jpe?g|png)$/i.test(file.name)) {
                var reader = new FileReader();
                reader.onloadend = () => {
                    this.setState({
                        image: reader.result,
                        fileError: false
                    })
                }
                reader.readAsDataURL(file);
            } else {
                this.setState({
                    fileError: true
                })
            }
        } else {
            this.setState({
                image: this.state.defaultFile,
                fileError: false
            })
        }
    }

    handleEmailChange = (changeValue) => {
        this.setState({
            consentEmail: changeValue
        })
    }

    handleSmsChange = (changeValue) => {
        this.setState({
            consentSms: changeValue
        })
    }

    handlePhoneChange = (changeValue) => {
        this.setState({
            consentTelephone: changeValue
        })
    }

    handleEmailOnChange = (e) => {
        this.setState({
            email: e.target.value,
            emailFormat: true
        })
    }

    handleInvoiceEmailOnChange = (e) => {
        this.setState({
            invoiceEmail: e.target.value,
            emailFormat: true
        })
    }

    handleStreet1 = (e) => {
        this.setState({
            street1: e.target.value,
            errorRequiredFields: false
        })
    }

    handleStreet2 = (e) => {
        this.setState({
            street2: e.target.value,
            errorRequiredFields: false
        })
    }

    handleCity = (e) => {
        this.setState({
            city: e.target.value,
            errorRequiredFields: false
        })
    }

    handleCountry = (e) => {
        this.setState({
            country: e.target.value,
            errorRequiredFields: false
        })
    }

    handleZipCode = (e) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                zipcode: e.target.value,
                errorRequiredFields: false
            })
        }
    }

    handleKeypress = (e) => {
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(e.charCode)
        if (e.keyCode != 8 && !pattern.test(inputChar)) {
            e.preventDefault();
        }
    }

    handleSubmit = () => {
        const pattern = /data:image\/([a-zA-Z]*);base64,/;
        if (FormValidation.validateEmail(this.state.email)) {
            if(this.state.street1 !="" && this.state.zipcode !="" && this.state.city !=""){
                this.setState({
                    errorRequiredFields: false
                })
                if (!this.state.fileError) {
                    const profileBody = {
                        image: this.state.image.replace(pattern, ""),
                        email: this.state.email,
                        consentSms: this.state.consentSms,
                        consentEmail: this.state.consentEmail,
                        consentTelephone: this.state.consentTelephone,
                        invoiceEmail: this.state.invoiceEmail,
                        dynamicsEntity : localStorage.getItem("dynamicsEntity"),
                        clientId:JSON.parse(localStorage.getItem("UserInfo")).userId,
                        postAddress:{
                            street1: this.state.street1,
                            street2: this.state.street2,
                            postalCode: this.state.zipcode,
                            city: this.state.city,
                            country: this.state.country
                        }
                    }
                    // Api call for save profile
                    this.props.profileSave(profileBody);
                }
            }else{
                this.setState({
                    errorRequiredFields: true
                })
            }
        } else {
            this.setState({
                emailFormat: false
            })
        }
    }

    handleLanguageChange = (countryCode) =>{
        if(countryCode == "US"){
            this.props.i18n.changeLanguage('en');
            localStorage.setItem('language','en');
        }
        else if(countryCode == "NO"){
            this.props.i18n.changeLanguage('no');
            localStorage.setItem('language','no');
        }
        this.setState({ selected: countryCode });
    }

    render() {
        const { selected } = this.state;
        if (this.props.fetching && !this.props.error) {
            return (<Loading />)
        }
        return (
            <div className="Dashboard">
                <Header userName={this.state.name}></Header>
                <div className="main-container">
                    <Sidebar />
                    {this.props.error ? <ErrorPage /> :
                        <div className="content-area">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <h2 className="pagettl mb-2">{this.props.t('myProfile.title')}</h2>
                                    <p>{this.props.t('myProfile.profileInfoText')}</p>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-12 col-md-12 col-lg-12 col-xl-4 mb-5">
                                    <h2 className="pagettlSmall">{this.props.t('myProfile.personalInformation')}</h2>
                                    <div className="generalbox">
                                        <div className="box=container">
                                            <ul className="profilelist">
                                                <li>
                                                    <span>{this.props.t('myProfile.personalName')}</span>
                                                    {this.state.name}
                                                </li>
                                                <li>
                                                    <span>{this.props.t('myProfile.personalEmail')}</span>
                                                    <input type="email" className="concentinput rounded border p-2" value={this.state.email} onChange={this.handleEmailOnChange} />
                                                </li>
                                                {window.globalConfig.CustomerName!=="Kreftforeningen" &&
                                                <li>
                                                    <span>{this.props.t('myProfile.invoiceEmail')}</span>
                                                    <input type="email" className="concentinput rounded border p-2" value={this.state.invoiceEmail} onChange={this.handleInvoiceEmailOnChange} />
                                                </li>
                                                }
                                                {this.state.emailFormat ? null : <p style={{ color: "red" }}>{this.props.t('onBoarding.emailError')}</p>}
                                                <li>
                                                {this.state.phoneNumber?<span>{this.props.t('myProfile.personalPhone')}</span>:""}
                                                {this.state.phoneNumber?this.state.phoneNumber:""}
                                                </li>
                                                <li>
                                                    <span>{this.props.t('myProfile.changeProfilePhoto')}</span>
                                                    <div className="d-flex mt-2 flex-column">
                                                        <img src={this.state.image} alt="" className="profileimg" />
                                                        <input type="file" accept=".jpeg,.jpg,.png" onChange={this.handlerFileChanged} />
                                                    </div>
                                                    {this.state.fileError ? <p style={{ color: "red" }}>{this.props.t('myProfile.imageErrorText')}</p> : null}
                                                </li>
                                            </ul>
                                            <p style={{textTransform:"uppercase", fontSize: "14px"}}>{this.props.t('myProfile.languageSelect')}</p>
                                            <ReactFlagsSelect
                                                    defaultCountry={this.state.language === 'en' ? "US" : "NO"}
                                                    countries={["US", "NO"]}
                                                    customLabels={{ "US": "EN", "NO": "NOR", }}
                                                    placeholder="Select Language"
                                                    selectedSize={20}
                                                    optionsSize={20}
                                                    onSelect={this.handleLanguageChange}
                                                    selected={selected}
                                                />
                                                <p style={{marginTop:"20px"}}>
                                                <a href={this.state.onBoardTermsAndCondition} target="_blank"> {this.props.t('onBoarding.consentTerms')}</a>
                                                </p>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-12 col-md-12 col-lg-6 col-xl-4 mb-5 d-none">
                                    <h2 className="pagettlSmall">{this.props.t('myProfile.companyInformation')}</h2>
                                    <div className="generalbox">
                                        <div className="box=container">
                                            <ul className="profilelist">
                                                <li>
                                                    <span>{this.props.t('myProfile.company')}</span>
                                                    {this.state.companyName}
                                                </li>
                                                <li>
                                                    <span>{this.props.t('myProfile.companyRole')}</span>
                                                    {this.state.role}
                                                </li>
                                                <li>
                                                    <span>{this.props.t('myProfile.companyAddress')}</span>
                                                    {this.state.address}
                                                </li>
                                                <li>
                                                    <span>{this.props.t('myProfile.companyZipCode')}</span>
                                                    {this.state.zipcode}
                                                </li>
                                                <li>
                                                    <span>{this.props.t('myProfile.city')}</span>
                                                    {this.state.city}
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-12 col-md-12 col-lg-6 col-xl-4 mb-5">
                                    <h2 className="pagettlSmall">{this.props.t('myProfile.addressTitle')}</h2>
                                    <div className="generalbox">
                                        <div className="box=container">
                                            <ul className="profilelist">
                                                <li>
                                                    <span>{this.props.t('myProfile.addressStreet1')} <p style={{ color: "red", display: "inline" }}>*</p></span>
                                                    <input type="text" style={{width:"90%"}} className="concentinput rounded border p-2" value={this.state.street1} onChange={this.handleStreet1} />
                                                </li>
                                                <li>
                                                    <span>{this.props.t('myProfile.addressStreet2')}</span>
                                                    <input type="text" style={{width:"90%"}} className="concentinput rounded border p-2" value={this.state.street2} onChange={this.handleStreet2} />
                                                </li>
                                                <li>
                                                    <span>{this.props.t('myProfile.addressPostCode')} <p style={{ color: "red", display: "inline" }}>*</p></span>
                                                    <input type="text" maxLength="4"
                                                    style={{width:"90%"}}
                                                        onChange={this.handleZipCode}
                                                        onKeyPress={this.handleKeypress}
                                                        className="concentinput rounded border p-2"
                                                        value={this.state.zipcode} />
                                                </li>
                                                <li>
                                                    <span>{this.props.t('myProfile.addressCity')} <p style={{ color: "red", display: "inline" }}>*</p></span>
                                                    <input type="text" style={{width:"90%"}} className="concentinput rounded border p-2" value={this.state.city} onChange={this.handleCity} />
                                                </li>
                                                <li>
                                                    <span>{this.props.t('myProfile.addressCountry')}</span>
                                                    <input type="text" style={{width:"90%"}} className="concentinput rounded border p-2" value={this.state.country} onChange={this.handleCountry} />
                                                </li>
                                                {
                                                    this.state.errorRequiredFields ? <p style={{ color: "red" }}>{this.props.t('myProfile.requiredFields')}</p> : null
                                                }
                                            </ul>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-12 col-md-12 col-lg-6 col-xl-4 mb-5">
                                    <h2 className="pagettlSmall">{this.props.t('myProfile.profileConsent')}</h2>
                                    <div className="generalbox consent">

                                        <div className="box-container mb-3">
                                            <div className="row">
                                                <div className="col-6 d-flex align-items-center">
                                                    {this.props.t('myProfile.consentEmail')}
                                                </div>
                                                <div className="col-6 text-right">
                                                    <BootstrapSwitchButton
                                                        width={110}
                                                        checked={this.state.consentEmail}
                                                        onlabel={this.props.t('myProfile.allow')}
                                                        offlabel={this.props.t('myProfile.deny')}
                                                        onChange={this.handleEmailChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="box-container mb-3">
                                            <div className="row">
                                                <div className="col-6 d-flex align-items-center">
                                                    {this.props.t('myProfile.consentPhone')}
                                                </div>
                                                <div className="col-6 text-right">
                                                    <BootstrapSwitchButton
                                                        width={110}
                                                        checked={this.state.consentTelephone}
                                                        onlabel={this.props.t('myProfile.allow')}
                                                        offlabel={this.props.t('myProfile.deny')}
                                                        onChange={this.handlePhoneChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="box-container mb-3">
                                            <div className="row">
                                                <div className="col-6 d-flex align-items-center">
                                                    {this.props.t('myProfile.consentSMS')}
                                                </div>
                                                <div className="col-6 text-right">
                                                    <BootstrapSwitchButton
                                                        width={110}
                                                        checked={this.state.consentSms}
                                                        onlabel={this.props.t('myProfile.allow')}
                                                        offlabel={this.props.t('myProfile.deny')}
                                                        onChange={this.handleSmsChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                            <div className="col-12 pt-5 text-center">
                                <Button className="btn rounded primarybtn pt-2 pb-2 pl-4 text-uppercase pr-4"
                                    onClick={this.handleSubmit}
                                >
                                    {this.props.t('myProfile.profileSaveButton')}
                                </Button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    getprofile: state.user.getProfile,
    saveprofile: state.user.saveProfile,
    fetching: state.user.fetching,
    error: state.error.isError
})

const mapDispatchToProps = {
    profile,
    profileSave,
    reset
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Profile)));