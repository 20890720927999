import React, {Component} from 'react';
import Form from 'react-bootstrap/Form';
import Surveytooltip from './SurveyTooltip';
import SurveyRequired from './SurveyRequired';

export default class SurveyMCQ extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            selectedMCQ:[]
        }
    }

    handleOnChangeMCQ = (e) => {
        if(this.state.selectedMCQ.includes(e.target.value)){
            const index = this.state.selectedMCQ.indexOf(e.target.value);
            const newList = this.state.selectedMCQ.splice(index, 1);
            this.setState({
                selectedMCQ: this.state.selectedMCQ
            },()=>{this.props.setUpdateMCQ({"required":this.props.data.isMandatoryQuestion,"data":this.state.selectedMCQ},this.props.uid)})
        }else{
            this.state.selectedMCQ.push(e.target.value);
            this.setState({
                selectedMCQ: this.state.selectedMCQ
            },()=>{this.props.setUpdateMCQ({"required":this.props.data.isMandatoryQuestion,"data":this.state.selectedMCQ},this.props.uid)})
        }   
    }

    render() {
        return (
            <Form.Group
                onChange={this.handleOnChangeMCQ}
            >
                <div className="surveyquestions"  id={this.props.data.relatedToQuestionId?this.props.data.relatedToQuestionId:""}>
                    <p>{this.props.data.question}{this.props.data.isMandatoryQuestion ? <SurveyRequired /> : ""}
                        {this.props.data.questionTooltip != ""? <Surveytooltip text={this.props.data.questionTooltip}/> : ""}
                    </p>
                    {this.props.data.multipleChoiceOptions.map((key,i)=>(
                        <Form.Check key={i}
                        type="checkbox"
                        label={key.trim()}
                        name={`formHorizontalRadios${i}${key}`}
                        id={`formHorizontalRadios${i}${key}`}
                        value={key.trim()}
                    />
                    ))} 
                </div>
            </Form.Group>
        )
    }
}
