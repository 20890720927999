import React, { Component } from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
//import Button from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
// Language translate import
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
// Connect react and redux
import { connect } from 'react-redux';
// Action call from redux
import { getLawyer } from '../../../redux/actions/UserActions';
import Loading from '../../Loader/Loader';
import {errorToast} from '../../Notification/Notification';

class StepTwo extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedLawyer: props.bookingData.step2 ? props.bookingData.step2.selectedLawyer : "",
            lawyerData: {},
            error: false,
            lawyers: []
        }

        this.handleRadioClick = this.handleRadioClick.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    componentWillMount() {
        if (this.props.bookingData.step1) {
            const data = this.props.bookingData.step1;
            if(data.legalBranch === ""){
                const requestParams = `?areaId=${data.legalArea}`;
                this.props.getLawyer(requestParams)
            }else{
                const requestParams = `?branchId=${data.legalBranch}&areaId=${data.legalArea}`;
                this.props.getLawyer(requestParams)
            }
        }

        if(Object.keys(this.props.bookingData).length == 0){
            this.props.goToStep1(1);
        }
    }

    componentWillReceiveProps(nextprops) {
        if (Object.keys(nextprops.lawyer).length > 0) {
            const lawyersData = nextprops.lawyer;
            if (lawyersData.Success) {
                this.setState({
                    lawyers: lawyersData.Result
                })
            } else {
                errorToast(lawyersData.Message)
            }
        }

    }

    handleRadioClick = (e) => {
        this.setState({
            selectedLawyer: e.target.value,
            error: false
        })
    }

    handleNext = () => {
        if (this.state.selectedLawyer != "" && this.state.lawyers != null) {
            const lawyerData = this.state.lawyers.filter(obj => obj.id === this.state.selectedLawyer)
            const newData = {
                selectedLawyer: this.state.selectedLawyer,
                lawyer: lawyerData
            }
            this.props.onupdateLawyer(newData);
            this.props.next();
        } else {
            this.setState({
                error: true
            })
        }

    }

    handleBack = () => {
        this.props.goToStep1(1);
    } 

    render() {
        return (
            <div>
                {/* Step 2  */}
                {this.props.fetching && <Loading /> }
                <h2 className="pagettlSmall">{this.props.t('booking.chooseLawyer')}</h2>
                <p>{this.props.t('booking.lawyerInfoText')} </p>

                <div className="box-container progressform mb-2">
                    <Table className="progresstable responsive">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th data-label="Name">{this.props.t('booking.lawyerGridName')}</th>
                                <th data-label="Description">{this.props.t('booking.lawyerGridDescription')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.lawyers == null ?
                                    <tr>
                                        <td style={{ width: '50px' }}>
                                        </td>
                                        <td style={{ width: '50px' }}>
                                        </td>
                                        <td data-label="Name">
                                        </td>
                                        <td data-label="Description">
                                        {this.props.t('booking.lawyerNullText')}
                                        </td>
                                    </tr>
                                    :
                                    this.state.lawyers.map((data, index) => (
                                        <tr key={index}>
                                            <td style={{ width: '50px' }}>
                                                <Form.Check type="radio" aria-label="radio"
                                                    name="lawyerRadios"
                                                    value={data.id}
                                                    checked={data.id == this.state.selectedLawyer}
                                                    onChange={this.handleRadioClick} />
                                            </td>
                                            <td style={{ width: '150px' }}>
                                                <img src={data.image} alt="" />
                                            </td>
                                            <td data-label="Name">
                                                <p>{data.name}</p>
                                                <p>{data.title}</p>
                                                <p>{data.email}</p>
                                                <p>{data.phone}</p>
                                            </td>
                                            <td data-label="Description">
                                                {data.description}
                                            </td>
                                        </tr>
                                    ))
                            }
                        </tbody>
                    </Table>
                    {
                        this.state.error ? <p style={{ color: "red" }}>{this.props.t('booking.lawyerErrorText')}</p> : null
                    }
                    <div className="row text-center pt-3">
                        <Button className="generalbtn pl-3 pr-3 pt-2 pb-2 m-auto"
                            onClick={this.handleBack}
                        >
                            {this.props.t('booking.back')}
                        </Button>
                        <Button className="generalbtn pl-3 pr-3 pt-2 pb-2 m-auto"
                            onClick={this.handleNext}
                            variant="secondary"
                            disabled={this.state.lawyers == null ? true : false }
                        >
                            {this.props.t('booking.next')}
                        </Button>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    lawyer: state.user.lawyer,
    fetching: state.user.fetching
})

const mapDispatchToProps = {
    getLawyer
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(StepTwo)));
