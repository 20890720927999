import {
    CHAT_REQUEST,
    CHAT_SUCCESS,
    CHAT_FAIL,
    CREATE_CHAT_REQUEST,
    CREATE_CHAT_SUCCESS,
    CREATE_CHAT_FAIL,
    UPDATE_CHAT_REQUEST,
    UPDATE_CHAT_SUCCESS,
    UPDATE_CHAT_FAIL,
	RESET,
    ISERRORSERVER
} from '../constants/App';
import {endPoints} from '../../config/ApiConfig';
import { get, post } from "./../../services/RestAPIWrapper";

// Get Chat
function getChatRequest() {
    return {
        type: CHAT_REQUEST
    }
}

function getChatReceiveData(json) {
    return {
        type: CHAT_SUCCESS,
        data: json
    }
}

export function getChat(chatParams) {

    return dispatch => {
        dispatch(getChatRequest());

        return get(endPoints.getChat.endPoint,chatParams,json => {
                if (json.Success == 'false') {
                    return dispatch({ type: CHAT_FAIL, data: json });
                } else {
                    return (dispatch(getChatReceiveData(json)));
                }
        	},err => {
                console.error(err);
                return dispatch(errorServer(err));
            });
    };
}


// Create Chat
function createChatRequest() {
    return {
        type: CREATE_CHAT_REQUEST
    }
}

function createChatReceiveData(json) {
    return {
        type: CREATE_CHAT_SUCCESS,
        data: json
    }
}

export function createChat(createChatObj) {

    return dispatch => {
        dispatch(createChatRequest());

        return post(endPoints.createChat.endPoint,createChatObj,json => {
                if (json.Success == 'false') {
                    return dispatch({ type: CREATE_CHAT_FAIL, data: json });
                } else {
                    return (dispatch(createChatReceiveData(json)));
                }
        	},err => {
                console.error(err);
                return dispatch(errorServer(err));
            });
    };
}


// update Chat
function updateChatRequest() {
    return {
        type: UPDATE_CHAT_REQUEST
    }
}

function updateChatReceiveData(json) {
    return {
        type: UPDATE_CHAT_SUCCESS,
        data: json
    }
}

export function updateChat(updateChatArr) {

    return dispatch => {
        dispatch(updateChatRequest());

        return post(endPoints.updateChat.endPoint,updateChatArr,json => {
                if (json.Success == 'false') {
                    return dispatch({ type: UPDATE_CHAT_FAIL, data: json });
                } else {
                    return (dispatch(updateChatReceiveData(json)));
                }
        	},err => {
                console.error(err);
                return dispatch(errorServer(err));
            });
    };
}


// Reset state 
function resetRequest() {
	return {
		type: RESET
	}
}
export function resetMessage() {

	return dispatch => {
		dispatch(resetRequest());
	};
}

// Error 
export function errorServer(error) {
	return {
        type: ISERRORSERVER,
        data: error
	}
}