import React from 'react';
import Routes from './routes';
import { Provider } from 'react-redux';
import {
  BrowserRouter
} from "react-router-dom";
import configureStore from './redux/store/configureStore';
if(window.globalConfig.CustomerName==="Kreftforeningen"){
  document.getElementsByTagName("body")[0].style='font-family: "Open Sans", sans-serif';
}
const favicon = document.getElementById("favicon");
const appleicon = document.getElementById("appleicon");
let faviconlogo="/"+window.globalConfig.CustomerName+".png";
let appleiconlogo="/"+window.globalConfig.CustomerName+"logo192.png";

favicon.href = faviconlogo;
appleicon.href = appleiconlogo;
document.title = window.globalConfig.title ? window.globalConfig.title : "Klient Portal";
const store = configureStore();
function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
