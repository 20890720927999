import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Surveytooltip from './SurveyTooltip';
import SurveyRequired from './SurveyRequired';
// Language translate import
import { withTranslation } from 'react-i18next';

class SurveyBoolen extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    handleOnChangeRadio = (e) => {
        this.props.setUpdateRadio({ "required": this.props.data.isMandatoryQuestion, "data": e.target.value,"questionId" :this.props.data.questionId }, this.props.uid)
    }
    render() {
        return (
            <Form.Group
                onChange={this.handleOnChangeRadio}
            >
                <div className="surveyquestions" id={this.props.data.relatedToQuestionId ? this.props.data.relatedToQuestionId : ""}>
                    <p>{this.props.data.question}{this.props.data.isMandatoryQuestion ? <SurveyRequired /> : ""}
                        {this.props.data.questionTooltip != "" ? <Surveytooltip text={this.props.data.questionTooltip} /> : ""}
                    </p>
                    <Form.Check
                        type="radio"
                        label={this.props.t('survey.yes')}
                        name={`${this.props.data.questionId}`}
                        id={`${this.props.data.questionId}1`}
                        value={this.props.t('survey.yes')}
                    />
                    <Form.Check
                        type="radio"
                        label={this.props.t('survey.no')}
                        name={`${this.props.data.questionId}`}
                        id={`${this.props.data.questionId}2`}
                        value={this.props.t('survey.no')}
                    />
                </div>
            </Form.Group>
        )
    }
}

export default (withTranslation('common')(SurveyBoolen));