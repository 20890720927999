import {
    LOGIN_NEWS_REQUEST,
    LOGIN_NEWS_SUCCESS,
    LOGIN_NEWS_FAIL,
    DASHBOARD_NEWS_REQUEST,
    DASHBOARD_NEWS_SUCCESS,
    DASHBOARD_NEWS_FAIL,
    NEWS_REQUEST,
    NEWS_SUCCESS,
    NEWS_FAIL
} from '../constants/App';

const initialState = {
    loginNews: {},
    dashBoardNews: {},
    news: {},
    fetching: false,
    error: ''
};

export default function newsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOGIN_NEWS_REQUEST:
            return {
                ...state,
                fetching: true,
                error: ''
            };
        case LOGIN_NEWS_SUCCESS:
            return {
                ...state,
                loginNews: action.data,
                fetching: false,
                error: ''
            };
        case LOGIN_NEWS_FAIL:
            return {
                ...state,
                loginNews: action.data,
                fetching: false,
                error: ''
            };
        case DASHBOARD_NEWS_REQUEST:
            return {
                ...state,
                fetching: true,
                error: ''
            };
        case DASHBOARD_NEWS_SUCCESS:
            return {
                ...state,
                dashBoardNews: action.data,
                fetching: false,
                error: ''
            };
        case DASHBOARD_NEWS_FAIL:
            return {
                ...state,
                dashBoardNews: action.data,
                fetching: false,
                error: ''
            };
        case NEWS_REQUEST:
            return {
                ...state,
                fetching: true,
                error: ''
            };
        case NEWS_SUCCESS:
            return {
                ...state,
                news: action.data,
                fetching: false,
                error: ''
            };
        case NEWS_FAIL:
            return {
                ...state,
                news: action.data,
                fetching: false,
                error: ''
            };
        default:
            return state;
    }
}