import {
    CHAT_REQUEST,
    CHAT_SUCCESS,
    CHAT_FAIL,
    CREATE_CHAT_REQUEST,
    CREATE_CHAT_SUCCESS,
    CREATE_CHAT_FAIL,
    UPDATE_CHAT_REQUEST,
    UPDATE_CHAT_SUCCESS,
    UPDATE_CHAT_FAIL,
	RESET
} from '../constants/App';

const initialState = {
	allMessage: {},
	fetching: false,
    error: '',
    oldMessageFetch: false,
    chatCreate: {},
    chatUpdate: {}
};

export default function MessageReducer(state = initialState, action = {}) {
	switch (action.type) {
		case CHAT_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case CHAT_SUCCESS:
			return {
				...state,
				allMessage: action.data,
				fetching: false,
				error: ''
			};
		case CHAT_FAIL:
			return {
				...state,
				allMessage: action.data,
				fetching: false,
				error: ''
            };
        case CREATE_CHAT_REQUEST:
            return {
                ...state,
                fetching: false,
                error: ''
            };
        case CREATE_CHAT_SUCCESS:
            return {
                ...state,
                chatCreate: action.data,
                fetching: false,
                error: ''
            };
        case CREATE_CHAT_FAIL:
            return {
                ...state,
                chatCreate: action.data,
                fetching: false,
                error: ''
            };
        case UPDATE_CHAT_REQUEST:
            return {
                ...state,
                fetching: false,
                error: ''
            };
        case UPDATE_CHAT_SUCCESS:
            return {
                ...state,
                chatUpdate: action.data,
                fetching: false,
                error: ''
            };
        case UPDATE_CHAT_FAIL:
            return {
                ...state,
                chatUpdate: action.data,
                fetching: false,
                error: ''
            };
        case RESET:
            return {
                allMessage: {},
                fetching: false,
                error: '',
                oldMessageFetch: false,
                chatCreate: {},
                chatUpdate:{}
            };
        default:
            return state;
    }
}