import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from "react-router";
//Badge
import Badge from 'react-bootstrap/Badge';
// Language translate import
import { withTranslation } from 'react-i18next';
// check client Authentication
import { checkClientAuthentication, checkOnBoardRequiredForPages } from '../../utils/restriction';
// Connect react and redux
import { connect } from 'react-redux';
// Action call from redux
import { reset, getThreadList } from '../../redux/actions/UserActions';
import Loading from '../Loader/Loader';
import { errorToast, successToast, infoToast } from '../Notification/Notification';
import ErrorPage from '../Error/ErrorPage';
import moment from 'moment';
import {dateTimeFormat} from '../../config/ApiConfig';

class MessageList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            allThreads: [],
            nextThread: 1,
            caseData:{},
            isLoadMore: true 
        }
    }

    componentWillMount() {
        this.props.reset();
        const check = checkOnBoardRequiredForPages(this.props);
        if (!check) {
            // check client Authentication
            const clinetAuthentication = checkClientAuthentication();
            if (!clinetAuthentication || !this.props.location.state) {
                this.props.history.push('/CaseList')
            } else {
                const caseid = this.props.location.state.caseId;
                this.setState({
                    caseData: this.props.location.state
                })
                const threadsListParams = `?caseId=${caseid}&pageNo=${this.state.nextThread}`;
                this.props.getThreadList(threadsListParams);
            }
        }
    }

    componentWillReceiveProps(nextprops) {
        if (Object.keys(nextprops.allthreads).length > 0) {
            const threadListData = nextprops.allthreads;
            if (threadListData.Success) {
                this.setState({
                    allThreads:[...new Set([...this.state.allThreads, ...threadListData.Result.Threads])],
                    isLoadMore: threadListData.Result.isMoreThreadAvailable,
                })
            } else {
                errorToast(threadListData.Message)
            }
        }
    }


    handleLoadMore = () => {
        const nextData = this.state.nextThread + 1;
        this.setState({
            nextThread: nextData
        });
        
        const caseid = this.props.location.state.caseId;
        const threadsListParams = `?caseId=${caseid}&pageNo=${nextData}`;
        this.props.getThreadList(threadsListParams);
    }

    handleBackClick = () => {
        this.props.history.push({
            pathname: '/Casedetails',
            state: {
                caseId: this.state.caseData.caseId
            }
        })
    }

    render() {
        return (
            <div className="Dashboard">
                {this.props.fetching && !this.props.error && <Loading />}
                <Header></Header>
                <div className="main-container">
                    <Sidebar test={this.state.xyz} />
                    {this.props.error ? <ErrorPage /> :
                        <div className="content-area-details alignedBox">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <h1 style={{float: "left"}} className="ml-3 mb-3 case-title">{this.props.t('caseDetails.message')}</h1>
                                        <Button style={{float: "right"}} className="generalbtn pl-3 pr-3 pt-2 pb-2 ml-auto message-btn" type="button" onClick={this.handleBackClick}>
                                            {this.props.t('caseDetails.backToCase')}
                                        </Button>
                                    </div>
                                </div>
                                <div className="item">
                                {
                                    this.state.allThreads.map((key, i) => (
                                        <ul key={i} className="MessageList">
                                            {
                                                !key.status ?
                                                    <li className="disabled">
                                                        <p className="font-weight-bold color1">{key.subject}</p>
                                                        <p className="moreText">{key.latestMessage.description}</p>
                                                        <p>{moment(key.createdOn).format(dateTimeFormat.dateFormat)}  | 
                                                        <Link to="#" className="colorred font-weight-bold">{this.props.t('caseDetails.closeMessage')}</Link></p>
                                                        <FontAwesomeIcon style={{fontSize:"25px"}} className="messageicon color3" icon={faCommentSlash} />
                                                    </li>
                                                    :
                                                    <Link to={{
                                                        pathname: "/Chat",
                                                        state: {
                                                            caseId: this.state.caseData.caseId,
                                                            caseTitle: this.state.caseData.caseTitle,
                                                            caseDescription: this.state.caseData.caseDescription,
                                                            messageId: key.id,
                                                            messageSubject: key.subject,
                                                            messageDate: moment(key.createdOn).format(dateTimeFormat.dateFormat)
                                                        }
                                                    }}>
                                                   <li className={key.latestMessage.HasRead? "": "unreadmessage"}>
                                                        <p className="font-weight-bold color1">{key.subject}
                                                        {key.latestMessage.HasRead? "": <Badge className="ml-2 newbage">{this.props.t('caseDetails.new')}</Badge>}
                                                        </p>
                                                        <p className="moreText">{key.latestMessage.description}</p>
                                                        <p>{moment(key.createdOn).format(dateTimeFormat.dateFormat)}  | 
                                                        <span className="font-weight-bold color1">{this.props.t('caseDetails.messageReply')}</span></p>
                                                        <FontAwesomeIcon className="messageicon color3" style={{fontSize:"25px"}} icon={faCommentDots} /> <span className="spanbadge">{key.UnReadMessageCount}</span>
                                                    </li>
                                                    </Link>
                                            }
                                        </ul>
                                    ))
                                }
                                </div>
                            </div>
                            {this.state.allThreads.length === 0 || !this.state.isLoadMore ? null : 
                            <div className="col-12 d-flex align-items-center justify-content-center">
                                <Button className="btn btn-primary generalbtn pl-5 pr-5" onClick={this.handleLoadMore}>
                                    {this.props.t('caseDetails.messageLoadMoreButton')}
                                </Button>
                            </div>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allthreads: state.user.allThreadList,
    fetching: state.user.fetching,
    error: state.error.isError
})

const mapDispatchToProps = {
    getThreadList,
    reset
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(MessageList)));