import React, { Component } from 'react';
// Language translate import
import { withTranslation } from 'react-i18next';

class ErrorPage extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }
    
    render() {
        return (
            <div>
                <div>
                    <div style={{ marginLeft: '10px', marginTop: '5%', textAlign:"center", fontSize: '25px',color:"Red" }}>
                        <p> {this.props.t('genericError.errorMessage')} </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation('common')(ErrorPage);