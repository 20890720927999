import {infoToast} from '../components/Notification/Notification';

// Client Authentication restriction check
export function checkClientAuthentication() {
    const isClientAuthenicated = JSON.parse(localStorage.getItem("UserInfo")).isClientAuthenticated;
    return isClientAuthenicated;
}


// Client oboarding restriction check
export function checkOnBoardRequired() {
    const isOnBoardRequired = JSON.parse(localStorage.getItem("UserInfo")).isOnboardingRequired;
    return isOnBoardRequired;
}


// Client oboarding restriction check
export function checkOnBoardRequiredForPages(props) {
    const isOnBoardRequired = JSON.parse(localStorage.getItem("UserInfo")).isOnboardingRequired;
    let value = false;
    if(isOnBoardRequired){
        props.history.push("OnBoarding");
        infoToast(props.t('onBoarding.consentMessage'))
        value = true;
    }
    return value;
}

export function surveyOnLogin(props,surveyId) {
    props.history.push({
        pathname: '/Survey',
        state: {
            surveyId: surveyId
        }
    });
}