import React, { Component } from 'react';
import Surveytooltip from './SurveyTooltip';
import SurveyRequired from './SurveyRequired';
import Form from 'react-bootstrap/Form';

export default class SurveyNumber extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    handleAmount = (e) => {
        if (this.props.number) {
            this.props.setUpdateAmount({"required":this.props.data.isMandatoryQuestion,"data":e.target.value},this.props.uid)
        } else {
            this.props.setUpdateWholeNO({"required":this.props.data.isMandatoryQuestion,"data":e.target.value},this.props.uid)
        }
    }

    render() {
        return (
            <Form.Group>
                <div className="surveyquestions"  id={this.props.data.relatedToQuestionId?this.props.data.relatedToQuestionId:""}>
                    <div className="row">
                        <div className="col-lg-6 col-sm-6">
                            <p>{this.props.data.question}{this.props.data.isMandatoryQuestion ? <SurveyRequired /> : ""}
                                {this.props.data.questionTooltip != "" ? <Surveytooltip text={this.props.data.questionTooltip} /> : ""}
                            </p>
                            <Form.Check
                                type="number"
                                id="formHorizontalRadios21"
                                className="surveynumber"
                                onChange={this.handleAmount}
                            />
                        </div>
                    </div>
                </div>
            </Form.Group>
        )
    }
}

