import React, { Component } from 'react'
import { withRouter } from "react-router";
import EmptyHeader from '../Header/EmptyHeader'
// Language translate import
import { withTranslation } from 'react-i18next';

class PublicSurveySuccess extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
          <div className="Dashboard">
            <EmptyHeader  name={""}/>
            <div className="main-container">
            <div className="content-area">
              <div className="row">
                <div className="col-12 col-lg-12 mb-4 removePadding">
                <div className="generalbox p-5">
                <h2 className="pagettlSmall" style={{textAlign:"center"}}>{this.props.t('survey.publicSurveySucess')}</h2>
                <div className="box-container progressform verifyinfo mb-2">
                  <p><h4 style={{textAlign:"center"}}>{this.props.t('survey.publicSurveyMessage')}</h4></p>
                </div>
                </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        );
    }
}

export default withRouter(withTranslation('common')(PublicSurveySuccess));