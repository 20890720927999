import React, { Component } from 'react'
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Button from 'react-bootstrap/Form';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from "react-router";
// Language translate import
import { withTranslation } from 'react-i18next';
// check client Authentication
import { checkClientAuthentication, checkOnBoardRequiredForPages } from '../../utils/restriction';
// Connect react and redux
import { connect } from 'react-redux';
// Action call from redux
import { reset } from '../../redux/actions/UserActions';
import { getChat, createChat, resetMessage, updateChat } from '../../redux/actions/MessageAction';
import Loading from '../Loader/Loader';
import { errorToast } from '../Notification/Notification';
import ErrorPage from '../Error/ErrorPage';
import moment from 'moment';
import { dateTimeFormat } from '../../config/ApiConfig';

class Chat extends Component {
    constructor(props) {
        super(props)

        this.state = {
            Id: "",
            caseTitle: "",
            caseDescription: "",
            chatTitle: "",
            chatDate: "",
            messageId: "",
            messages: [],
            nextMessage: 1,
            isMoreMessage: false,
            textMessage: "",
            requestForOldMessage: false,
            messageIds: [],
            stopCall: true
        }
        this.scrollOfMessage = React.createRef();
    }
    componentWillMount() {
        this.props.reset();
        this.props.resetMessage();
        const check = checkOnBoardRequiredForPages(this.props);
        if (!check) {
            // check client Authentication
            const clinetAuthentication = checkClientAuthentication();
            if (!clinetAuthentication || !this.props.location.state) {
                this.props.history.push('/CaseList')
            } else {
                const messageData = this.props.location.state
                this.setState({
                    Id: messageData.caseId,
                    caseTitle: messageData.caseTitle,
                    caseDescription: messageData.caseDescription,
                    chatTitle: messageData.messageSubject,
                    chatDate: messageData.messageDate,
                    messageId: messageData.messageId,
                })
                const messageParams = `?threadId=${messageData.messageId}&pageNo=${this.state.nextMessage}`;
                this.props.getChat(messageParams);
            }
        }
    }

    componentWillReceiveProps(nextprops) {
        if (Object.keys(nextprops.getallmessage).length > 0) {
            const allMessage = nextprops.getallmessage;
            if (allMessage.Success) {
                if (this.state.requestForOldMessage) {
                    this.setState({
                        messages: [...new Set([...this.state.messages, ...allMessage.Result.Messages])],
                        isMoreMessage: allMessage.Result.isMoreMessagesAvailable,
                        messageIds: allMessage.Result.messageIds,
                        stopCall: true
                    })
                    setTimeout(() => {
                        this.componentDidMount();
                    }, 200);
                } else {
                    this.setState({
                        messages: [...new Set([...allMessage.Result.Messages])],
                        isMoreMessage: allMessage.Result.isMoreMessagesAvailable,
                        messageIds: allMessage.Result.messageIds
                    })
                    setTimeout(() => {
                        this.handleScrollEvent();
                    }, 100);
                }
            } else {
                errorToast(allMessage.Message)
            }
        }

        if (Object.keys(nextprops.createmessage).length > 0) {
            const createMessage = nextprops.createmessage;
            if (createMessage.Success) {
                this.props.resetMessage();
                const messageParams = `?threadId=${this.state.messageId}&pageNo=${this.state.nextMessage}`;
                this.props.getChat(messageParams);
            } else {
                errorToast(createMessage.Message)
            }
        }

        if (Object.keys(nextprops.updatemessage).length > 0) {
            const updateMessage = nextprops.updatemessage;
            if (updateMessage.Success) {
                this.props.resetMessage();
                this.setState({
                    stopCall: false
                })
                clearInterval(this.intervalID);
            } else {
                errorToast(updateMessage.Message)
                clearInterval(this.intervalID);
            }
        }
    }

    handleMessageInput = (e) => {
        this.setState({
            textMessage: e.target.value,
            requestForOldMessage: false
        })
    }

    updateStateForRequestOldMessages = () => {
        this.setState({
            requestForOldMessage: true
        })
    }

    handleOnScrollEvent = (e) => {
        if (e.target.scrollTop === 0 && this.state.isMoreMessage) {
            this.updateStateForRequestOldMessages();
            const nextData = this.state.nextMessage + 1;
            this.setState({
                nextMessage: nextData
            });
            const messageParams = `?threadId=${this.state.messageId}&pageNo=${nextData}`;
            this.props.getChat(messageParams);
        }
    }

    handleScrollEvent = () => {
        this.scrollOfMessage.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
    }

    handleSendMessage = () => {
        const checkSpaces = /^\s*$/;
        if (this.state.textMessage !== "" && !checkSpaces.test(this.state.textMessage)) {
            const messageObj = {
                "threadId": this.state.messageId,
                "caseId": this.state.Id,
                "subject": this.state.chatTitle,
                "description": this.state.textMessage,
                "createdFrom" : "ClientPortal"
            }
            this.props.createChat(messageObj)
            this.setState({
                textMessage: "",
                nextMessage: 1
            })
        } else {
            this.setState({
                textMessage: "",
                nextMessage: 1
            })
        }
    }

    handleBackClick = () => {
        this.props.history.push({
            pathname: '/Casedetails',
            state: {
                caseId: this.state.Id
            }
        })
    }

    componentDidMount(){
        if(this.state.stopCall){
            this.intervalID  = setInterval(() => {
                if(this.state.messageIds.length > 0){
                    this.props.updateChat(this.state.messageIds);
                    this.setState({
                        stopCall: false
                    })
                }else{
                    clearInterval(this.intervalID)
                    this.setState({
                        stopCall: false
                    })
                }
            }, 3000);
        }
    }


    render() {
        const sortMessages = this.state.messages
        const sortByDates = (a, b) => moment(a.createdOn) - moment(b.createdOn)
        const sortedDates = sortMessages.sort(sortByDates)
        return (
            <div className="Dashboard">
                {this.props.fetching && !this.props.error && <Loading />}
                <Header></Header>
                <div className="main-container">
                    <Sidebar />
                    {this.props.error ? <ErrorPage /> :
                        <div className="chat-main-container">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <h2 className="pagettl mb-2">{this.props.t('caseDetails.message')}</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <div className="message-head">
                                        <div>
                                            <h2 className="pagettl mb-2">{this.state.caseTitle}</h2>
                                            <p>{this.state.caseDescription}</p>
                                        </div>
                                        <Button className="generalbtn pl-3 pr-3 pt-2 pb-2 ml-auto message-btn" type="button" onClick={this.handleBackClick}>
                                            {this.props.t('caseDetails.backToCase')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="row h-100 chatheight">
                                <div className="col-md-12 mb-3">
                                    <div className="chat-message-box">
                                        <div className="message-header">
                                            <h4>{this.state.chatTitle}</h4>
                                            <h6>{this.state.chatDate}</h6>
                                        </div>
                                        <div className="message-chat-container" onScroll={this.state.isMoreMessage ? this.handleOnScrollEvent : null}>
                                            <ul ref={this.scrollOfMessage}>
                                                {
                                                    this.state.messages.length === 0 ? null
                                                        :
                                                        this.state.messages.map((item, i) => (
                                                            item.isLoginUser ?
                                                                <li key={i} className="message-chat-right">
                                                                    <div className="message-chat-box">
                                                                        <p>{item.description}</p>
                                                                        <div className="message-time">
                                                                            {moment(item.createdOn).format(dateTimeFormat.dateFormat)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="message-user-img">
                                                                        <img src={item.image} />
                                                                    </div>
                                                                </li>
                                                                :
                                                                <li key={i} className="message-chat-left">
                                                                    <div className="message-user-img">
                                                                        <img src={item.image} />
                                                                    </div>
                                                                    <div className="message-chat-box">
                                                                        <h4>{item.name}</h4>
                                                                        <p>{item.description}</p>
                                                                        <div className="message-time">
                                                                            {moment(item.createdOn).format(dateTimeFormat.dateFormat)}
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                        ))
                                                }
                                            </ul>
                                        </div>
                                        <div className="message-footer">
                                            <Form.Control as="textarea" rows="3"
                                                placeholder={this.props.t('caseDetails.chatInput')}
                                                onChange={this.handleMessageInput}
                                                value={this.state.textMessage}
                                            />
                                            <Button className="message-send-btn" type="button" onClick={this.handleSendMessage}>
                                                <FontAwesomeIcon style={{ color: "#ffffff" }}  icon={faPaperPlane} />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    getallmessage: state.message.allMessage,
    createmessage: state.message.chatCreate,
    updatemessage: state.message.chatUpdate,
    fetching: state.message.fetching,
    error: state.message.isError
})

const mapDispatchToProps = {
    getChat,
    createChat,
    resetMessage,
    updateChat,
    reset
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Chat)));