import React, { Component } from 'react'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Form';
import { withRouter } from "react-router";
// Language translate import
import { withTranslation } from 'react-i18next';
// Connect react and redux
import { connect } from 'react-redux';
// Action call from redux
import { getArea, getBranches, getrelationShips } from '../../../redux/actions/UserActions';
import Loading from '../../Loader/Loader';
import { errorToast } from '../../Notification/Notification';
// check client Authentication
import { checkOnBoardRequiredForPages } from '../../../utils/restriction';

class StepOne extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subject: props.bookingData.step1 ? props.bookingData.step1.subject : "",
            description: props.bookingData.step1 ? props.bookingData.step1.description : "",
            time: props.bookingData.step1 ? props.bookingData.step1.time : "",
            legalBranch: props.bookingData.step1 ? props.bookingData.step1.legalBranch : "",
            legalArea: props.bookingData.step1 ? props.bookingData.step1.legalArea : "",
            relationShip: props.bookingData.step1 ? props.bookingData.step1.relationShip : "",
            error: false,
            area: [],
            relationShips: [],
            branches: [],
            allBranches: [],
            bookingTitle: "",
        }

        this.handleSubject = this.handleSubject.bind(this);
        this.handleDescription = this.handleDescription.bind(this);
        this.handleTime = this.handleTime.bind(this);
        this.handleLegalBranch = this.handleLegalBranch.bind(this);
        this.handleLegalArea = this.handleLegalArea.bind(this);
        this.handleRelationShip= this.handleRelationShip.bind(this);
        this.handleNext = this.handleNext.bind(this);

    }

    componentWillMount() {
        this.setState({
            bookingTitle: localStorage.getItem("bookingMessage") ? localStorage.getItem("bookingMessage") : "Avtaleskjema for videokonsultasjon",
        })
        const check = checkOnBoardRequiredForPages(this.props);
        if (!check) {
            this.props.getArea();
            this.props.getBranches();
            if (window.globalConfig.CustomerName === "Kreftforeningen") {
                this.props.getrelationShips();
            }
        }
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.props.bookingData.step1) {
                const branchesFilter = this.state.allBranches.filter(obj => obj.ParentId === this.state.legalArea)
                this.setState({
                    branches: branchesFilter
                })
            }
        }, 2000);
    }

    componentWillReceiveProps(nextprops) {
        if (Object.keys(nextprops.getarea).length > 0) {
            const areas = nextprops.getarea;
            if (areas.Success) {
                this.setState({
                    area: areas.Result
                })
            } else {
                errorToast(areas.Message)
            }
        }

        if (Object.keys(nextprops.getbranches).length > 0) {
            const branchesData = nextprops.getbranches;
            if (branchesData.Success) {
                this.setState({
                    allBranches: branchesData.Result
                })
            } else {
                errorToast(branchesData.Message)
            }
        }
       
        if (Object.keys(nextprops.getrelationships).length > 0) {
            const relationShipsData = nextprops.getrelationships;
            if (relationShipsData.Success) {
                this.setState({
                    relationShips: relationShipsData.Result
                })
            } else {
                errorToast(relationShipsData.Message)
            }
        }
    }

    handleSubject = (e) => {
        this.setState({
            subject: e.target.value
        })
    }

    handleDescription = (e) => {
        this.setState({
            description: e.target.value
        })
    }

    handleTime = (e) => {
        this.setState({
            time: e.target.value
        })
    }

    handleLegalBranch = (e) => {
        this.setState({
            legalBranch: e.target.value,
            error: false
        })
    }
    handleRelationShip= (e)=>{
        this.setState({
            relationShip:e.target.value
        })
    }

    handleLegalArea = (e) => {
        const branchesFilter = this.state.allBranches.filter(obj => obj.ParentId === e.target.value)
        this.setState({
            legalArea: e.target.value,
            error: false,
            branches: branchesFilter,
            legalBranch: ""
        })
    }
    createBooking = (e) => {
        const branchesFilter = this.state.allBranches.filter(obj => obj.ParentId === e.target.value)
        this.setState({
            legalArea: e.target.value,
            error: false,
            branches: branchesFilter,
            legalBranch: ""
        })
    }
    handleNext = () => {
        const step1Data = this.state;
        const areaName = this.state.area.filter(obj => obj.Id === this.state.legalArea);
        const branchName = this.state.branches.filter(obj => obj.Id === this.state.legalBranch);
        const relationShipName = this.state.relationShips.filter(obj => obj.Id === this.state.relationShip);
        let branchData = "";
        if (step1Data.subject !=="" && step1Data.description !=="") {
            if ((step1Data.time ==="" && step1Data.relationShip!=="" && window.globalConfig.CustomerName === "Kreftforeningen") || (step1Data.time !=="" && step1Data.legalArea !=="" && window.globalConfig.CustomerName !=="Kreftforeningen")) {
                this.setState({
                    error: false
                });
                if (step1Data.legalBranch != "") {
                    branchData = branchName[0]['Name']
                }

                const data = {
                    subject: step1Data.subject,
                    description: step1Data.description,
                    time: step1Data.time,
                    legalBranch: step1Data.legalBranch,
                    legalArea: step1Data.legalArea,
                    relationShip:step1Data.relationShip,
                    selectedRelationShip:relationShipName && relationShipName.length>0?relationShipName['Name']:"",
                    selectedAreaName: areaName.length > 0 && areaName != undefined ? areaName[0]['Name'] : "",
                    selectedBranchName: branchData
                };
                this.props.onupdateBooking(data);
                this.props.next();
            }
            else {
                this.setState({
                    error: true
                })
            }
        } else {
            this.setState({
                error: true
            })
        }

    }

    render() {
        return (
            <div>
                {this.props.fetching && <Loading />}
                <h2 className="pagettlSmall">{this.state.bookingTitle}</h2>

                <div className="box-container progressform mb-4">
                    <Form>
                        <Form.Row className="mb-3">
                            <Form.Label column sm={2}>{this.props.t('booking.subject')}<span style={{ color: "red" }}>*</span></Form.Label>
                            <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                <Form.Control
                                    type="text"
                                    placeholder={this.props.t('booking.subjectPlaceHolder')}
                                    onChange={this.handleSubject}
                                    value={this.state.subject}
                                />
                            </Col>
                        </Form.Row>

                        <Form.Row className="mb-3">
                            <Form.Label column sm={2}>{this.props.t('booking.description')}<span style={{ color: "red" }}>*</span></Form.Label>
                            <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                <Form.Control as="textarea" rows="3"
                                    placeholder={this.props.t('booking.descriptionPlaceHolder')}
                                    onChange={this.handleDescription}
                                    value={this.state.description}
                                />
                            </Col>
                        </Form.Row>
                        {window.globalConfig.CustomerName !== "Kreftforeningen" &&
                            <Form.Row className="mb-3">
                                <Form.Label column sm={2}>{this.props.t('booking.time')}<span style={{ color: "red" }}>*</span></Form.Label>
                                <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                    <Form.Control
                                        type="text"
                                        placeholder={this.props.t('booking.timePlaceHolder')}
                                        onChange={this.handleTime}
                                        value={this.state.time}
                                    />
                                </Col>
                            </Form.Row>
                        }
                        <Form.Row className="mb-3">
                            <Form.Label column sm={2}>{this.props.t('booking.legalArea')}<span style={{ color: "red" }}>*</span></Form.Label>
                            <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                <Form.Control as="select" value={this.state.legalArea} onChange={this.handleLegalArea}>
                                    <option value="" defaultValue disabled hidden>{this.props.t('booking.select')}</option>
                                    {
                                        this.state.area.map((item, index) => (
                                            <option value={item.Id} key={index}>{item.Name}</option>
                                        ))
                                    }
                                </Form.Control>
                            </Col>
                        </Form.Row>
                        {window.globalConfig.CustomerName === "Kreftforeningen" &&
                            <Form.Row className="mb-3">
                                <Form.Label column sm={2}>{this.props.t('booking.relationShip')}<span style={{ color: "red" }}>*</span></Form.Label>
                                <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                    <Form.Control as="select"
                                        value={this.state.relationShip}
                                        onChange={this.handleRelationShip}
                                    >
                                        <option value="">{this.props.t('booking.selectRelationShip')}</option>
                                        {
                                            this.state.relationShips.map((item, index) => (
                                                <option value={item.Id} key={index}>{item.Name}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Col>
                            </Form.Row>
                        }
                        {window.globalConfig.CustomerName !== "Kreftforeningen" &&
                            <Form.Row className="mb-3">
                                <Form.Label column sm={2}>{this.props.t('booking.legalBranch')}</Form.Label>
                                <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                    <Form.Control as="select"
                                        value={this.state.legalBranch}
                                        onChange={this.handleLegalBranch}
                                        disabled={this.state.legalArea != "" ? false : true}
                                    >
                                        <option value="">{this.props.t('booking.select')}</option>
                                        {
                                            this.state.branches.map((item, index) => (
                                                <option value={item.Id} key={index}>{item.Name}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Col>
                            </Form.Row>
                        }
                        {
                            this.state.error ? <p style={{ color: "red" }}>{this.props.t('booking.bookingErrorText')}</p> : null
                        }
                        <Form.Row className="mb-3 text-uppercase pt-4 text-center">
                            <Button className="generalbtn pl-3 pr-3 pt-2 pb-2 m-auto" type="submit" onClick={this.handleNext}>
                                {this.props.t('booking.selectLawerButton')}
                            </Button>
                        </Form.Row>
                    </Form>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    getarea: state.user.area,
    getrelationships:state.user.relationship,
    getbranches: state.user.branches,
    fetching: state.user.fetching
})

const mapDispatchToProps = {
    getArea,
    getBranches,
    getrelationShips
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(StepOne)));