import React, { Component } from 'react'


import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { Button } from 'react-bootstrap';
// Bootstrap switch 
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Checkbox from 'react-simple-checkbox';
//Routing
import { withRouter } from "react-router";
//Input validation
import FormValidation from "../../utils/inputValidation";
// Connect react and redux
import { connect } from 'react-redux';
// Action call from redux
import { userConsentSave, reset } from '../../redux/actions/UserActions';
// Language translate import
import { withTranslation } from 'react-i18next';
// check client Authentication
import { checkOnBoardRequired } from '../../utils/restriction';
import Loading from '../Loader/Loader';
import { errorToast, successToast } from '../Notification/Notification';
import ErrorPage from '../Error/ErrorPage';
// Change language dropdown
import ReactFlagsSelect from 'react-flags-select';
import './../../assets/css/Dashboard.css';
class OnBoarding extends Component {

    constructor(props) {
        super(props)

        this.state = {
            email: props.location.state ? props.location.state.email : "",
            consentSms: JSON.parse(localStorage.getItem("UserInfo")).defaultVippsConcent,
            consentEmail: JSON.parse(localStorage.getItem("UserInfo")).defaultVippsConcent,
            consentTelephone: JSON.parse(localStorage.getItem("UserInfo")).defaultVippsConcent,
            userNumber: props.location.state ? props.location.state.phoneNumber : "",
            name: props.location.state ? props.location.state.name : "",
            agreeTerms: false,
            emailFormat: true,
            language: localStorage.getItem('language'),
            street1: "",
            street2: "",
            city: "",
            country: "",
            zipcode: "",
            onBoardTermsAndCondition : ""
        }

    }
    componentWillMount() {
        const checkOnboarding = checkOnBoardRequired();
        if (!checkOnboarding) {
            this.props.history.push("Dashboard");
        }

        const userInfo = JSON.parse(localStorage.getItem("UserInfo"));
        this.setState({
            email: userInfo.email,
            userNumber: userInfo.phone_number,
            name: userInfo.name,
            street1: userInfo.street1,
            city: userInfo.city,
            country: userInfo.country,
            zipcode: userInfo.zipcode,
            onBoardTermsAndCondition :  localStorage.getItem("portalTermsUrl"),
            selected:'NO',
        })
    }

    componentWillReceiveProps(nextprops) {
        if (Object.keys(nextprops.consentSave).length > 0) {
            if (nextprops.consentSave.Success) {
                successToast(nextprops.consentSave.Message)
                const newData = JSON.parse(localStorage.getItem('UserInfo'));
                const storeData = {
                    ...newData,
                    isOnboardingRequired: false
                }
                localStorage.setItem("UserInfo", JSON.stringify(storeData));
                setTimeout(() => {
                    const data = localStorage.getItem("sideBar");
                    if (data !== undefined && data !== "" && data !== null) {
                        let SideBar = JSON.parse(data);
                        let sideBarConfig = SideBar.filter(obj => obj.includes("active"));
                        let activesideBarConfig = sideBarConfig[0].split(":")[0] ? sideBarConfig[0].split(":")[0] : "";
                        activesideBarConfig ? this.props.history.push(activesideBarConfig) : this.props.history.push("Dashboard");

                    }
                    else {
                        this.props.history.push('Dashboard')
                    }
                }, 1000);
                this.props.reset()
            } else {
                errorToast(nextprops.consentSave.Message)
            }
        }
    }

    handleLanguageChange = (countryCode) =>{
        if(countryCode == "US"){
            this.props.i18n.changeLanguage('en');
            localStorage.setItem('language','en');
        }
        else if(countryCode == "NO"){
            this.props.i18n.changeLanguage('no');
            localStorage.setItem('language','no');
        }
        this.setState({ selected: countryCode });
    }

    handleEmailOnChange = (e) => {
        this.setState({
            email: e.target.value,
            emailFormat: true
        })
    }

    handleSmsChange = (changeValue) => {
        this.setState({
            consentSms: changeValue
        })
    }

    handlePhoneChange = (changeValue) => {
        this.setState({
            consentTelephone: changeValue
        })
    }

    handleEmailChange = (changeValue) => {
        this.setState({
            consentEmail: changeValue
        })
    }
    handleStreet1 = (e) => {
        this.setState({
            street1: e.target.value,
            errorRequiredFields: false
        })
    }

    handleStreet2 = (e) => {
        this.setState({
            street2: e.target.value,
            errorRequiredFields: false
        })
    }

    handleCity = (e) => {
        this.setState({
            city: e.target.value,
            errorRequiredFields: false
        })
    }

    handleCountry = (e) => {
        this.setState({
            country: e.target.value,
            errorRequiredFields: false
        })
    }

    handleZipCode = (e) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                zipcode: e.target.value,
                errorRequiredFields: false
            })
        }
    }


    handleSubmit = () => {
        if (this.state.agreeTerms) {
            if (FormValidation.validateEmail(this.state.email)) {
                // Api call to save the data
                const consentSaveBody = {
                    phoneNumber: this.state.userNumber,
                    consentSms: this.state.consentSms,
                    consentEmail: this.state.consentEmail,
                    consentTelephone: this.state.consentTelephone,
                    email: this.state.email,
                    dynamicsEntity :localStorage.getItem("dynamicsEntity"),
                    clientId:JSON.parse(localStorage.getItem("UserInfo")).userId,
                    address: {
                        street_address: this.state.street1,
                        street_address2: this.state.street2,
                        postal_code: this.state.zipcode,
                        region: this.state.city,
                        country: this.state.country,
                        formatted: "",
                        address_type: "",
                      }
                }
                this.props.userConsentSave(consentSaveBody);
            } else {
                this.setState({
                    emailFormat: false
                })
            }
        }
        else {
            errorToast(this.props.t('onBoarding.consentTermsError'))
        }
    }

    handleAgreeTerms = (value) => {
        this.setState({
            agreeTerms: value
        })
    }

    render() {
        const { selected } = this.state;
        return (
            <div className="Dashboard">
                {this.props.fetching && <Loading />}
                <Header />
                <div className="main-container">
                    <Sidebar />
                    {this.props.error ? <ErrorPage /> :
                        <div className="content-area">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <h2 className="pagettl mb-2">{this.props.t('onBoarding.title')}</h2>
                                    <p>{this.props.t('onBoarding.infoMessage')}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 col-xl-4 mb-5">
                                    <h2 className="pagettlSmall">{this.props.t('myProfile.personalInformation')}</h2>
                                    <div className="generalbox">
                                        <div className="box=container">
                                            <ul className="profilelist">
                                                <li>
                                                    <span>{this.props.t('myProfile.personalName')}</span>
                                                    {this.state.name}
                                                </li>
                                                <li>
                                                    <span>{this.props.t('myProfile.personalEmail')}</span>
                                                    <input type="text" className="concentinput rounded border p-2" value={this.state.email} onChange={this.handleEmailOnChange} />
                                                </li>
                                                {this.state.emailFormat ? null : <p style={{ color: "red" }}>{this.props.t('onBoarding.emailError')}</p>}
                                                <li>
                                                {this.state.phoneNumber?<span>{this.props.t('myProfile.personalPhone')}</span>:""}
                                                {this.state.phoneNumber?this.state.phoneNumber:""}
                                                </li>
                                                <li className="d-flex align-items-center">
                                                    <Checkbox
                                                        color="#122e43"
                                                        className="agree-terms"
                                                        size="3"
                                                        tickSize="3"
                                                        checked={this.state.agreeTerms}
                                                        onChange={this.handleAgreeTerms}
                                                    />
                                                    <a href={this.state.onBoardTermsAndCondition} target="_blank"> {this.props.t('onBoarding.consentTerms')}</a>
                                                </li>
                                            </ul>
                                            <p style={{textTransform:"uppercase", fontSize: "14px"}}>{this.props.t('myProfile.languageSelect')}</p>
                                            <ReactFlagsSelect
                                                    defaultCountry={this.state.language === 'en' ? "US" : "NO"}
                                                    countries={["US", "NO"]}
                                                    customLabels={{ "US": "EN", "NO": "NOR", }}
                                                    placeholder="Select Language"
                                                    selectedSize={20}
                                                    optionsSize={20}
                                                    onSelect={this.handleLanguageChange}
                                                    selected={selected}
                                                />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-12 col-lg-6 col-xl-4 mb-5">
                                    <h2 className="pagettlSmall">{this.props.t('myProfile.addressTitle')}</h2>
                                    <div className="generalbox">
                                        <div className="box=container">
                                            <ul className="profilelist">
                                                <li>
                                                    <span>{this.props.t('myProfile.addressStreet1')} <p style={{ color: "red", display: "inline" }}>*</p></span>
                                                    <input type="text" style={{width:"90%"}} className="concentinput rounded border p-2" value={this.state.street1} onChange={this.handleStreet1} />
                                                </li>
                                                <li>
                                                    <span>{this.props.t('myProfile.addressStreet2')}</span>
                                                    <input type="text" style={{width:"90%"}} className="concentinput rounded border p-2" value={this.state.street2} onChange={this.handleStreet2} />
                                                </li>
                                                <li>
                                                    <span>{this.props.t('myProfile.addressPostCode')} <p style={{ color: "red", display: "inline" }}>*</p></span>
                                                    <input type="text" maxLength="4"
                                                    style={{width:"90%"}}
                                                        onChange={this.handleZipCode}
                                                        onKeyPress={this.handleKeypress}
                                                        className="concentinput rounded border p-2"
                                                        value={this.state.zipcode} />
                                                </li>
                                                <li>
                                                    <span>{this.props.t('myProfile.addressCity')} <p style={{ color: "red", display: "inline" }}>*</p></span>
                                                    <input type="text" style={{width:"90%"}} className="concentinput rounded border p-2" value={this.state.city} onChange={this.handleCity} />
                                                </li>
                                                <li>
                                                    <span>{this.props.t('myProfile.addressCountry')}</span>
                                                    <input type="text" style={{width:"90%"}} className="concentinput rounded border p-2" value={this.state.country} onChange={this.handleCountry} />
                                                </li>
                                                {
                                                    this.state.errorRequiredFields ? <p style={{ color: "red" }}>{this.props.t('myProfile.requiredFields')}</p> : null
                                                }
                                            </ul>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-12 col-md-12 col-lg-6 col-xl-4 mb-5">
                                    <h2 className="pagettlSmall">{this.props.t('myProfile.profileConsent')}</h2>
                                    <div className="generalbox consent">

                                        <div className="box-container mb-3">
                                            <div className="row">
                                                <div className="col-6 d-flex align-items-center">
                                                    {this.props.t('myProfile.consentEmail')}
                                                </div>
                                                <div className="col-6 text-right">
                                                    <BootstrapSwitchButton
                                                        width={110}
                                                        checked={this.state.consentEmail}
                                                        onlabel={this.props.t('myProfile.allow')}
                                                        offlabel={this.props.t('myProfile.deny')}
                                                        onChange={this.handleEmailChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="box-container mb-3">
                                            <div className="row">
                                                <div className="col-6 d-flex align-items-center">
                                                    {this.props.t('myProfile.consentPhone')}
                                                </div>
                                                <div className="col-6 text-right">
                                                    <BootstrapSwitchButton
                                                        width={110}
                                                        checked={this.state.consentTelephone}
                                                        onlabel={this.props.t('myProfile.allow')}
                                                        offlabel={this.props.t('myProfile.deny')}
                                                        onChange={this.handlePhoneChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="box-container mb-3">
                                            <div className="row">
                                                <div className="col-6 d-flex align-items-center">
                                                    {this.props.t('myProfile.consentSMS')}
                                                </div>
                                                <div className="col-6 text-right">
                                                    <BootstrapSwitchButton
                                                        width={110}
                                                        checked={this.state.consentSms}
                                                        onlabel={this.props.t('myProfile.allow')}
                                                        offlabel={this.props.t('myProfile.deny')}
                                                        onChange={this.handleSmsChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 pt-5 text-center">
                                <Button className="btn rounded primarybtn pl-4 pr-4" onClick={this.handleSubmit}>
                                    {this.props.t('onBoarding.consentSaveButton')}
                                </Button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    consentSave: state.user.userConsentSave,
    fetching: state.user.fetching,
    error: state.error.isError
})

const mapDispatchToProps = {
    userConsentSave,
    reset
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(OnBoarding)));