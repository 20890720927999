import { toast,Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Toast configuration
toast.configure({
    autoClose: 2500,
    draggable: false,
    transition: Slide,
    position: "bottom-center",
    hideProgressBar: false,
    pauseOnHover: true,
    newestOnTop:true
});

export function successToast(message) {
    //toast.success(message);
    toast(message,{
        className: "toast-success-style",
        bodyClassName: "toast-success-body",
        progressClassName: "toast-success-progress"
    });
}

export function errorToast(message) {
    toast.error(message);
}

export function warningToast(message) {
    toast.warn(message);
}

export function infoToast(message) {
    toast.info(message,{
        toastId: 13
    });
}

export function dismissInfo() {
    toast.dismiss(13);
}