import React, { Component } from 'react';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import StepOne from "./steps/StepOne";
import StepThree from "./steps/StepThree";
import StepFour from "./steps/StepFour";
// Language translate import
import { withTranslation } from 'react-i18next';
// check client Authentication
import { checkClientAuthentication, checkOnBoardRequiredForPages } from '../../utils/restriction';
// Connect react and redux
import { connect } from 'react-redux';
import ErrorPage from '../Error/ErrorPage';
import './../../assets/css/Dashboard.css';
class BookingWizard extends Component {

    constructor(props) {
        super(props)

        this.state = {
            currentstep: 1,
            bookingData: {}
        }
    }

    componentWillMount() {
        const check = checkOnBoardRequiredForPages(this.props);
        if (!check) {
            //check client Authentication
            const clinetAuthentication = checkClientAuthentication();
            if (!clinetAuthentication) {
                this.props.history.push('/Dashboard')
            }
        }
    }


    goNext = () => {
        var current = this.state.currentstep;
        this.setState({
            currentstep: current + 1
        })
    }

    goBack = (step) => {
        this.setState({
            currentstep: step
        })
    }

    onUpdateAfterBooking = (data) => {

        let updateData = {
            ...this.state.bookingData,
            step1: data
        }

        this.setState({
            bookingData: updateData
        })
    }

    onUpdateAfterLawyer = (data) => {

        let updateData = {
            ...this.state.bookingData,
            step2: data
        }

        this.setState({
            bookingData: updateData
        })
    }

    resetBookingData = () => {
        this.setState({
            bookingData: {}
        })
    }

    onBookingMessage = (message) => {
        let updateData = {
            ...this.state.bookingData,
            bookingMessage: message
        }
        this.setState({
            bookingData: updateData
        })
    }

    render() {
        return (
            <div className="Dashboard">
                <Header></Header>
                <div className="main-container">
                    <Sidebar />
                    {this.props.error ? <ErrorPage /> :
                        <div className="content-area">
                            <div className="row">
                                <div className="col-md-12 mb-5 progresssteps">

                                    <ul>
                                        <li className={this.state.currentstep > 0 ? "active" : null}>
                                            <a style={{ cursor: "pointer" }} onClick={() => { this.goBack(1) }}>
                                                {this.props.t('booking.bookingStepName')}
                                                <span></span>
                                            </a>
                                        </li>
                                        <li className={this.state.currentstep > 1 ? "active" : null}>
                                            <a>
                                                {this.props.t('booking.informationStepName')}
                                                <span></span>
                                            </a>
                                        </li>
                                        <li className={this.state.currentstep > 2 ? "active" : null}>
                                            <a>
                                                {this.props.t('booking.confirmationStepName')}
                                                <span></span>
                                            </a>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-12 mb-4">
                                    <div className="generalbox p-5">
                                        {
                                            this.state.currentstep == 1
                                                ?
                                                <StepOne
                                                    next={this.goNext}
                                                    onupdateBooking={this.onUpdateAfterBooking}
                                                    bookingData={this.state.bookingData}
                                                />
                                                :
                                                null
                                        }                               
                                        {
                                            this.state.currentstep == 2
                                                ?
                                                <StepThree
                                                    next={this.goNext}
                                                    bookingData={this.state.bookingData}
                                                    goToStep2={this.goBack}
                                                    bookMessage={this.onBookingMessage}
                                                />
                                                :
                                                null
                                        }

                                        {
                                            this.state.currentstep == 3
                                                ?
                                                <StepFour
                                                    bookingData={this.state.bookingData}
                                                    next={this.goNext}
                                                    reset={this.resetBookingData} />
                                                :
                                                null
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    error: state.error.isError
})

export default connect(mapStateToProps, null)(withTranslation('common')(BookingWizard));