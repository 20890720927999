import React, { Component } from 'react';
import Surveytooltip from './SurveyTooltip';
import SurveyRequired from './SurveyRequired';
import Form from 'react-bootstrap/Form';
import ReactStars from "react-rating-stars-component";
export default class Survey6Star extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    handlerating = (e) => {
        this.props.setUpdate6StarRating({ "required": this.props.data.isMandatoryQuestion, "data": e }, this.props.uid)
    }

    render() {
        return (
            <Form.Group>
                <div className="surveyquestions" id={this.props.data.relatedToQuestionId?this.props.data.relatedToQuestionId:""}>
                    <div className="row">
                        <div className="col-lg-6 col-sm-6">
                            <p>{this.props.data.question}{this.props.data.isMandatoryQuestion ? <SurveyRequired /> : ""}
                                {this.props.data.questionTooltip != "" ? <Surveytooltip text={this.props.data.questionTooltip} /> : ""}
                            </p>
                            <ReactStars
                                count={6}
                                onChange={this.handlerating}
                                size={35}
                                activeColor="#ffd700"
                            />
                        </div>
                    </div>
                </div>
            </Form.Group>
        )
    }
}

