import { combineReducers } from 'redux';

import user from './user';
import news from './news';
import error from './error';
import message from './message';

export default combineReducers({
	user,
	news,
	error,
	message
});